import React from 'react';
import { Button } from '../..';
import { useResponsive } from '../../../hooks';

export const AuthLayout: React.FC = ({ children }) => {
  const _navigateToAdmin = () => {
    document.location.href = 'https://www.finessa.co.za';
  };
  const responsive = useResponsive();
  const _aboutUs = (): void => {
    window.open('https://www.finessa.co.za/about-us.php', '_blank');
  };

  const _services = (): void => {
    window.open('https://www.finessa.co.za/services.php', '_blank');
  };

  return (
    <>
      <div className="bg-auth-background bg-cover h-screen absolute bottom-0 top-0 left-0 right-0 -z-10" />
      <div className="flex flex-1 flex-col h-screen">
        <nav className="px-4 justify-between bg-secondary h-20">
          <ul className="h-full">
            <li className="py-3 h-full">
              <Button
                onClick={_navigateToAdmin}
                className="bg-transparent shadow-none hover:bg-transparent hover:shadow-none"
              >
                <img
                  src="assets/images/fines-sa-logo-white.png"
                  alt="finesSA logo"
                  className="nav-logo"
                />
              </Button>
            </li>
          </ul>
        </nav>
        {responsive.isMobile ? (
          <div className="py-4 inline-block overflow-y-auto">
            <div className="flex flex-1 items-center justify-center mb-3">{children}</div>
            <div className="border-t-2 border-text-grey flex flex-col px-6">
              <p className="footer-text">Copyright © {new Date().getFullYear()} FinesSA</p>
              <Button onClick={_aboutUs} type="text" className="footer-text">
                About Us
              </Button>
              <Button onClick={_services} type="text" className="footer-text">
                Services
              </Button>
            </div>
          </div>
        ) : (
          <>
            <div className="py-4 xs:inline-block xs:overflow-y-auto h-full">
              <div className="flex flex-1 items-center justify-center min-h-full">{children}</div>
            </div>
            <div className="h-20 bottom-0 border-t-2 border-text-grey justify-between flex px-6">
              <p className="footer-text">Copyright © {new Date().getFullYear()} FinesSA</p>
              <div className="flex">
                <Button onClick={_aboutUs} type="text" className="footer-text">
                  About Us
                </Button>
                <Button onClick={_services} type="text" className="footer-text">
                  Services
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
