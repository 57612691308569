import React, { useState } from 'react';
import { FieldArray, Formik, FormikHelpers } from 'formik';
import _ from 'lodash';
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { IconButton } from '@material-ui/core';
import { ArrowBack, HighlightOff } from '@material-ui/icons';

import { Button, FormTextField, OffenderTypeTextField } from '../../atoms';
import { ErrorObject, BaseFormProps, AddProfileFleetEntityAndLinkProps } from '../../../types';
import { OffenderIdentifierType, OffenderIdentifierTypeDescription } from '../../../enums';
import { fleetEntityFormValidationSchema } from '../../../validators';

export const AddUserFleetEntityForm: React.FC<BaseFormProps<AddProfileFleetEntityAndLinkProps>> = ({
  initialValues,
  submitForm,
  onSuccess,
  entityLinkIndex,
  fleetEntityId,
  setEdit,
  openModal,
}) => {
  const linkTypes = [OffenderIdentifierType.Brn, OffenderIdentifierType.ProxyId];

  const _handleFormSubmitError = (
    error: ErrorObject,
    actions: FormikHelpers<AddProfileFleetEntityAndLinkProps>,
  ) => {
    actions.setSubmitting(false);
    const apiErrors = error.errors;
    if (!_.isEmpty(apiErrors)) {
      actions.setFieldError('businessEntities', apiErrors.businessEntities);
    }
    if (error.statusCode === 422) {
      actions.setFieldError('businessEntities', error.detail);
    }
  };

  const _handleSubmission = (
    formData: AddProfileFleetEntityAndLinkProps,
    actions: FormikHelpers<AddProfileFleetEntityAndLinkProps>,
  ) => {
    submitForm(formData)
      .then(() => {
        onSuccess();
      })
      .catch((error: ErrorObject) => {
        _handleFormSubmitError(error, actions);
      })
      .finally(() => actions.setSubmitting(false));
  };

  return (
    <Formik
      initialValues={initialValues}
      validateOnChange={false}
      validationSchema={fleetEntityFormValidationSchema}
      onSubmit={_handleSubmission}
      entityLinkIndex={entityLinkIndex}
      fleetEntityId={fleetEntityId}
      setEdit={setEdit}
      openModal={openModal}
    >
      {({
        handleSubmit,
        isSubmitting,
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleReset,
      }) => {
        const [displayToolTip, setDisplayToolTip] = useState(false);
        const toolTip = () => {
          return (
            <>
              <div className="flex flex-row bg-blue-400 rounded-lg text-white p-1 m-2">
                <div className="flex justify-center items-center p-2">
                  <EmojiObjectsOutlinedIcon />
                </div>
                <p className="text-left">
                  A BRN (Business Registration Number) is the unique 13 digit number issued to a
                  business by the Vehicle Registration Authority.
                </p>
              </div>
            </>
          );
        };
        return (
          <form
            onReset={handleReset}
            onSubmit={handleSubmit}
            className="flex auth-form-edit-body items-center h-formThird  overflow-auto"
          >
            <div className="flex flex-col flex-1 self-center w-4/5 contain-content">
              <div className="flex flex-row">
                <IconButton aria-label="delete" size="small" onClick={() => openModal('business')}>
                  <ArrowBack fontSize="small" />
                </IconButton>
                <p className="auth-heading ml-5">Entity Details</p>
              </div>
              <hr className="bg-black h-0.7 border-black mt-3" />
              <div className="flex-col">
                <p className="text-sm font-bold mt-3">Entity Name:</p>
                <FormTextField
                  variableName="entityName"
                  placeholder="Entity Name"
                  values={values}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched}
                  errors={errors}
                  icon={<img src="./assets/icons/building-icon.png" alt="businessentity" />}
                  required
                  className="flex"
                />

                <p className="flex text-sm font-bold mt-3">Address:</p>
                <FormTextField
                  variableName="address"
                  placeholder="Address"
                  values={values}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched}
                  errors={errors}
                  icon={<img src="assets/icons/building-icon.png" alt="buildingName" />}
                  required
                  className=" flex"
                />

                <p className="flex text-sm font-bold mt-3">Vat Number:</p>
                <FormTextField
                  variableName="vatNumber"
                  placeholder="Vat Number"
                  values={values}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched}
                  errors={errors}
                  icon={<img src="assets/icons/contact-icon.png" alt="contact" />}
                  required
                  className=" flex "
                />

                <p className="flex text-sm font-bold mt-3">Company Registration:</p>
                <FormTextField
                  variableName="companyRegistration"
                  placeholder="Company Registration"
                  values={values}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched}
                  errors={errors}
                  icon={<img src="assets/icons/id-card-icon.png" alt="companyReg" />}
                  required
                  className=" flex "
                />
                {displayToolTip && toolTip()}

                <p className="flex text-sm font-bold mt-3">BRN/Proxy ID:</p>
                <FieldArray
                  name="links"
                  render={({ remove, push }) => (
                    <div className="w-full">
                      {values.links.length > 0 &&
                        values.links.map((link, index) => {
                          return (
                            <div className="flex flex-row" key={link.id}>
                              <div className="flex w-9/12">
                                <OffenderTypeTextField
                                  variableType={`links[${index}].identifierType`}
                                  variableTypes={linkTypes}
                                  variableName={`links[${index}].value`}
                                  values={values}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  touched={touched}
                                  errors={errors}
                                  required
                                  className="mb-2 flex"
                                  endIcon={
                                    <IconButton
                                      className="h-4 w-4 m-0 p-0"
                                      onClick={() => remove(index)}
                                    >
                                      <HighlightOff className="h-4 w-4 m-0 p-0" />
                                    </IconButton>
                                  }
                                  placeholder={
                                    OffenderIdentifierTypeDescription[
                                      values.links[index].identifierType
                                    ]
                                  }
                                />
                              </div>
                              {index === 0 ? (
                                <div className="p-1 w-5">
                                  <IconButton
                                    onClick={() => {
                                      setDisplayToolTip(!displayToolTip);
                                    }}
                                    className="p-1 w-4 "
                                    disableFocusRipple
                                    disableRipple
                                  >
                                    <InfoOutlinedIcon className="h-4 w-4" />
                                  </IconButton>
                                </div>
                              ) : (
                                <div className="p-1 w-5 h-4" />
                              )}
                            </div>
                          );
                        })}
                      <button
                        type="button"
                        className="flex justify-start text-secondary self-end "
                        onClick={() =>
                          push({
                            id: crypto.randomUUID(),
                            identifierType: OffenderIdentifierType.Brn,
                            value: '',
                          })
                        }
                      >
                        Add more
                      </button>
                    </div>
                  )}
                />

                <Button
                  isLoading={isSubmitting}
                  onClick={handleSubmit}
                  className="flex w-full bg-primary hover:bg-primary-dark text-white text-base self-center mt-3"
                >
                  Add
                </Button>
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};
