import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { CircularProgress } from '@material-ui/core';
import { useMutation } from 'react-query';
import { useQueryParam } from 'use-query-params';

import { invoiceService } from '../../../services';

const customStyles = () => {
  return {
    overlay: {
      zIndex: 1000,
      background:
        'linear-gradient(290deg, #061F40D9 0%, #031020D9 10%, #101925D9 30%, #0C0C0CD9 100%) 0% 0% no-repeat padding-box',
      boxShadow: '0px 20px 50px #3E3E3E4D',
      filter: 'blur(0px)',
      overflowY: 'hidden',
    },
    content: {
      top: '50%',
      left: '50%',
      width: '20%',
      height: '20%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '30px',
      padding: 0,
      zIndex: 100,
      opacity: 1,
    },
  };
};

Modal.setAppElement('#root');

const RedirectToPayFast: React.FC = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  };

  const [invoiceId] = useQueryParam<string>('InvoiceId');
  const [source] = useQueryParam<string>('source');
  const getGatewayAddress = useMutation(() =>
    invoiceService.createInvoicePaymentLink(invoiceId, Number.parseInt(source, 10)),
  );

  const _redirectToPayFast = async () => {
    const result = await getGatewayAddress.mutateAsync();
    window.open(result.redirectUrl, '_self');
  };

  useEffect(() => {
    openModal();
    _redirectToPayFast();
  }, [invoiceId, source]);

  return (
    <>
      <Modal isOpen={modalIsOpen} style={customStyles()} contentLabel="Mobile Modal">
        <div className="flex h-14">
          <div className="absolute left-10 top-16">
            <div className="flex flex-row">
              <CircularProgress />
              <div className="ml-3">Redirecting to payment gateway...</div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default RedirectToPayFast;
