import { OffenderIdentifierType } from '../../enums';
import { OffenderIdentifier } from '../profile.types';

export type ErrorObject = {
  errors: any;
  statusCode: number;
  message: string;
  detail: string;
};

export type ServiceResultError = {
  message: string;
  code: number;
};

export interface ServiceResult<T> {
  succeeded: boolean;
  data: T;
  error: ServiceResultError;
}

export interface PaginatedList<T> {
  items: T[];
  pageIndex: number;
  totalPages: number;
  totalCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export interface PaymentPartnerDto {
  id: Guid;
  name: string;
  displayName: string;
}

export type IndividualFilterData = {
  fullName: string;
  firstName: string;
  lastName: string;
  idNumber: string;
  identifierType: OffenderIdentifierType;
};

export type IndividualFilterDataDto = {
  fullName: string;
  firstName: string;
  lastName: string;
  idNumber: string;
  identifierType: number;
};

export type PortalFineStatus = 'Unpaid' | 'Paid';

export type BusinessEntity = {
  id: Guid;
  businessName: string;
  identificationNumbers: string[];
  offenderIdentifiers: OffenderIdentifier[];
};

export type GroupedBusinessFine = {
  offenderIdentifier: string;
  fines: FineDetailDto[];
};

export type UnpaidFine = {
  id: string;
  noticeNumber: string;
  vehicleRegistration: string;
  reason: string;
  area: string;
  date: string;
  amountDue: number;
  discount: number;
  aartoFee: number;
  enforcementOrder: number;
  serviceFee: number;
  total: number;
  amountPaid: number;
  outstandingAmount: number;
  checked: boolean;
  photoId?: string;
  evidenceTokens: string[];
  isNag: boolean;
};
export type UnpaidOffender = {
  offenderName: string;
  offenderId: string;
  fines: FineDetailDto[];
};

export type PaymentUrlDto = {
  redirectUrl: string;
};

export type InvoicePrintTemplateDto = {
  template: string;
};

export type UnpaidOffenderBusiness = {
  entityName: string;
  links: UnpaidOffenderLink[];
};

export type UnpaidOffenderLink = {
  identifier: string;
  idType: string;
  fines: UnpaidFine[];
};

export type PaidFine = {
  id: string;
  noticeNumber: string;
  vehicleRegistration: string;
  date: string;
  amountPaid: number;
  fineAmount: number;
  paymentType: 'Pay Fast' | 'EFT';
};

export type PaidOffender = {
  offenderName: string;
  offenderId: string;
  fines: PaidFine[];
};

export type PaidBusinessFine = {
  data: PaidOffenderBusiness[];
};

export type PaidOffenderBusiness = {
  entityName: string;
  links: PaidOffenderLink[];
};

export type PaidOffenderLink = {
  identifier: string;
  idType: string;
  fines: PaidFine[];
};

export type User = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  roles: string;
  extension: string;
};

export type FineDetailDto = {
  id: string;
  offenderIdentifier: string;
  noticeNumber: string;
  vehicleRegistration: string;
  area: string;
  date: Date;
  amountDue: number;
  discount: number;
  aartoFee: number;
  enforcementOrder: number;
  serviceFee: number;
  fineAmount: number;
  evidence: string[];
  otherFees: number;
  total: number;
  amountPaid: number;
  isNag: boolean;
  isPaid: boolean;
  reason: string;
  paymentType: string;
  isPayable: boolean;
  status: string;
  paidDate: Date;
  demeritPoints: number;
};

export type GeneratePasswordLinkDto = {
  link: string;
};

export type Invoices = {
  id: string;
  offenderIdentifier: string;
  recipientName: string;
  referenceNumber: string;
  amountPaid: number;
  amountDue: number;
  createdDate: number;
  receivedPayment: number;
  finesPaid: number;
};

export type fleetEntities = {
  id: string;
  businessName: string;
  businessRegistration: string;
  businessAddress: string;
  vatNo: string;
};

export type EditFineProps = {
  vehicleRegistration: string;
  offenceDate: Date;
  municipality: string;
  offenceLocation: string;
  offenceDescription: string;
  manualDiscount: number | null;
  amountDue: number | null;
  noticeType: NoticeType;
  serviceFee: number | null;
};

export type EditFineFormMeta = {
  id: Guid;
  offenderIdentifier: OffenderIdentifier;
  isNag: boolean;
  noticeTypeKnown: boolean;
  noticeType: NoticeType;
  isAarto: boolean;
  status: FineStatus;
  role: string;
};

export enum NoticeType {
  // Criminal Act
  Notice = 0,
  Summons = 2,
  Warrant = 4,

  // Aarto
  CourtesyLetter = 1,
  EnforcementOrder = 3,
  Unknown = 999,
}

export type GetFineByIdDto = {
  id: Guid;
  vehicleRegistration: string;
  offenceDate: Date;
  municipality: string;
  offenceLocation: string;
  offenceDescription: string;
  manualDiscount: number | null;
  noticeType: NoticeType;
  offenderIdentifier: OffenderIdentifier;
  isNag: boolean;
  noticeTypeKnown: boolean;
  serviceProvider: FineServiceProvider;
  amountDue: number;
  isAarto: boolean;
  serviceFee: number | null;
  status: FineStatus;
};

export enum FineStatus {
  Unpaid = 0,
  ProvisionallyPaid = 1,
  SettledWithMunicipality = 2,
  PaidThroughOtherAvenue = 3,
  PaymentAcknowledgedByServiceProvider = 4,
  Withdrawn = 5,
  ContestedWithMunicipality = 6,
}

export enum FineStatusDisplay {
  Unpaid = 0,
  'Provisionally Paid' = 1,
  'Settled With Municipality' = 2,
  'Paid Through Other Avenue' = 3,
  'Payment Acknowledged By Service Provider' = 4,
  'Withdrawn' = 5,
  'Contested With Municipality' = 6,
}

export enum FineServiceProvider {
  Syntell = 0,
  Tmt = 1,
  Manual = 2,
  TmtCapeTown = 3,
}

export type EditFleetEntitiesLinkProps = {
  identifierType: string;
  value: string;
};

export type Guid = string;

export type InvoiceDetailDto = {
  id: Guid;
  offenderIdentifier: string;
  recipientName: string;
  poNumber: string;
  vatNumber: string;
  companyRegistrationNumber: string;
  address: string;
  referenceNumber: string;
  paymentReceivedDate: string;
  dateCreated: string;
  dueDate: string;
  invoiceStatus: string;
  subTotal: number;
  PaymentReceivedDate: string;
  amountReceived: number;
  invoiceItems: InvoiceOffenderDto[];
  totalDiscount: number;
  referralDiscount: number;
  additionalInformationKey: string;
  additionalInformationValue: string;
};

export type PortalInvoiceDetailDto = {
  id: Guid;
  offenderIdentifier: string;
  recipientName: string;
  vatNumber: string;
  companyRegistrationNumber: string;
  address: string;
  referenceNumber: string;
  paymentReceivedDate: string;
  dateCreated: string;
  dueDate: string;
  invoiceStatus: string;
  subTotal: number;
  PaymentReceivedDate: string;
  amountReceived: number;
  invoiceOffenders: InvoiceOffenderDto[]; // TODO: Change
};

export type InvoiceOffenderDto = {
  invoiceOffender: string;
  invoiceItems: InvoiceItemDto[];
};

export type InvoiceItemDto = {
  fineId: string;
  noticeNumber: string;
  reason: string;
  offenceLocation: string;
  municipality: string;
  offenceDate: string;
  vehicleRegistrationNumber: string;
  fineAmount: number;
  discount: number;
  amountPaid: number;
  enforcementOrder: number;
  serviceFee: number;
  warrantFee: number;
  fees: number;
  total: number;
  totalOutstanding: number;
  municipalityReceiptNumber: string;
};

export type CallHistoryDto = {
  callDate: string;
  agentFirstName: string;
  callContactFirstName: string;
  callOutcome: CallOutcome;
  durationInSeconds: number;
};

export type NextCallDto = {
  dailyCallId?: Guid;
  callContactId?: Guid;
  userId?: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  partner: string;
  agentFullName?: string;
  notes?: string;
};

export type EditCallCentreProfileFormFields = {
  userId: string;
  firstName: string;
  lastName: string;
  cellphoneNumber: string;
  email: string;
  notes?: string;
  partner: string;
};

export type CallOutcomeFormFields = {
  callDate: string;
  agentFullName: string;
  callOutcome: CallOutcome;
  durationInSeconds: number;
  doNotContact: boolean;
  callBack: boolean;
  callBackDate?: Date;
};

export type FinishCallProps = {
  callContactId: Guid;
  callId: Guid;
  callOutcome: CallOutcome;
  durationInSeconds: number;
  doNotContact: boolean;
  callBackDate?: Date;
};

export enum CallOutcome {
  NoAnswer = 0,
  Voicemail = 1,
  NotAvailable = 2,
  ClientWillPay = 3,
  AmendedEmailAddress = 4,
  WrongNumber = 5,
  NoMoney = 6,
  CallBackLater = 7,
  DataUpdated = 8,
  Unknown = 999,
}

export enum CallOutcomeDescriptionEnum {
  'No Answer' = 0,
  'Voicemail' = 1,
  'Not Available' = 2,
  'Client Will Pay' = 3,
  'Amended Email Address' = 4,
  'Wrong Number' = 5,
  'No Money' = 6,
  'Call Back Later' = 7,
  'Data Updated' = 8,
}

export type EditProfileFleetEntityAndLinkProps = {
  entityName: string;
  address: string;
  vatNumber: string;
  companyRegistration: string;
  links: EditProfileFleetEntityLinkProps[];
};

export type EditProfileFleetEntityLinkProps = {
  id: string;
  value: string;
  identifierType: OffenderIdentifierType;
};

export type AddProfileFleetEntityAndLinkProps = {
  entityName: string;
  address: string;
  vatNumber: string;
  companyRegistration: string;
  links: AddProfileFleetEntityLinkProps[];
};

export type AddProfileFleetEntityLinkProps = {
  id: string;
  value: string;
  identifierType: OffenderIdentifierType;
};

export type SetNoteProps = {
  profileId: Guid;
  isHuman: boolean;
  note: string;
};

export type FineFilterOptions = {
  noticeNumber: string;
  offenderIdentifierValue: string;
  vehicleRegistration: string;
  byNag: boolean;
  pageNum: number;
  pageEntries: number;
};

export type PaymentPartnerFineFilterOptions = {
  noticeNumber: string;
  byNag: boolean;
  pageNum: number;
  pageEntries: number;
  invoiceItemCreationReferenceId?: Guid;
  status: FineStatus;
};

export type InvoiceFilterOptions = {
  referenceNumber: string;
  offenderIdentifierValue: string;
  recipientName: string;
  vatNumber: string;
  address: string;
  companyRegistrationNumber: string;
  pageNum: number;
  pageEntries: number;
};

export enum FinePaymentStatus {
  Unpaid = 0,
  Paid = 1,
  Other = 2,
}

export type PaginatedProfileFinesQuery = {
  filter: PaginatedProfileFinesFilterOptions;
  pageNumber: number;
  pageEntries: number;
  orderBy: string;
};

export type PaginatedProfileFinesFilterOptions = {
  paymentStatus: FinePaymentStatus;
  offenderIdentifierValues: string[];
  vehicleRegistrations: string[];
  noticeNumber: string;
  onlyNag: boolean;
};

export type ReportFilterProps = {
  resultAttributes?: string[];
  filterDateBy?: string;
  fromDate?: string;
  toDate?: string;
};
