import { useQuery } from 'react-query';
import { AccountProfiles, Guid, ProfileInfoProps, ProfileType } from '../../types';
import { getAccountProfiles } from '../../services/api/admin-profiles/admin-profiles.service';

export const useAccountInfoQuery = (userId: Guid, profileType: ProfileType) => {
  return useQuery<AccountProfiles, void, ProfileInfoProps>(
    ['AdminAccountProfileData', userId],
    () => getAccountProfiles(userId),
    {
      enabled: !!userId,
      select: (result) => {
        return {
          id: result.id,
          firstName: result.firstName,
          lastName: result.lastName,
          identifier: result?.offenderIdentifier?.value ?? '',
          email: result.email,
          cellPhoneNumber: result.phoneNumber,
          updateProfileType: profileType,
          signUpInformation: result.signUpInformation,
          communicationEmails: result.communicationEmails,
          isArchived: result.isArchived,
          doNotContact: result.doNotContact,
          identifierType: result?.offenderIdentifier?.identifierType,
          isWhatsAppVerified: result.isWhatsAppVerified,
        };
      },
    },
  );
};
