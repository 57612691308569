import React, { useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { useMutation, useQuery } from 'react-query';
import _ from 'lodash';
import { toast } from 'react-hot-toast';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import replaceall from 'replaceall';
import { useQueryParam } from 'use-query-params';
import { TailwindButton, UnpaidFineSummary, UnpaidTableRow } from '../../../components';
import { invoiceService } from '../../../services';
import { Guid } from '../../../types';

type InvoiceParams = {
  id: Guid;
};
const ViewInvoiceScreen: React.FC = () => {
  const { id } = useParams<InvoiceParams>();
  const [autoPrintEnabled] = useQueryParam<boolean>('autoPrintEnabled');
  const [autoPrintCount, setAutoPrintCount] = useState(0);
  const [source] = useQueryParam<number>('source');

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const useCreateInvoicePrintTemplateMutation = useMutation('create-invoice-print-template', () =>
    invoiceService.createInvoicePrintTemplate(id),
  );
  const useCreatePaymentLinkMutation = useMutation(
    'create-payment-link',
    () => invoiceService.createInvoicePaymentLink(id, source),
    {
      onSuccess: (data) => {
        window.open(data.redirectUrl, '_self');
      },
      onError: () => {
        toast.error('Could not create payment url');
      },
    },
  );

  const today = useMemo(() => new Date(), []);

  const useInvoiceItemsQuery = useQuery(
    ['portal-invoice', id],
    () => invoiceService.getInvoice(id),
    {
      onSuccess: () => {
        if (autoPrintEnabled && autoPrintCount < 1) {
          useCreateInvoicePrintTemplateMutation.mutateAsync().then(() => {
            handlePrint();
            setAutoPrintCount(autoPrintCount + 1);
          });
        }
      },
      select: (data) =>
        data.invoiceOffenders.map((invoiceOffender) => {
          return {
            offenderId: invoiceOffender.invoiceOffender,
            offenderName: invoiceOffender.invoiceOffender,
            fines: invoiceOffender.invoiceItems.map((ii) => ({
              offenderIdentifier: '',
              id: ii.fineId,
              aartoFee: ii.warrantFee,
              serviceFee: ii.serviceFee,
              area: ii.municipality,
              amountDue: ii.totalOutstanding,
              amountPaid: ii.amountPaid,
              date: new Date(ii.offenceDate),
              discount: ii.discount,
              fineAmount: ii.fineAmount,
              enforcementOrder: ii.enforcementOrder,
              evidence: [],
              isNag: false,
              isPayable: true,
              noticeNumber: ii.noticeNumber,
              vehicleRegistration: ii.vehicleRegistrationNumber,
              otherFees: 0,
              paymentType: '',
              reason: ii.reason,
              total: ii.total,
            })),
          };
        }),
    },
  );

  const ComponentToPrint = React.forwardRef(() => {
    let newPdfString = replaceall(
      `src=C:\\home\\site\\wwwroot../../../Common/FinesSA.RazorTemplates/Images/logo_finessa.png`,
      `src="assets/images/logo_finessa.png"`,
      useCreateInvoicePrintTemplateMutation?.data?.template ?? '',
    );
    newPdfString = replaceall(
      `src=C:\\home\\site\\wwwroot../../../Common/FinesSA.RazorTemplates/Images/blue_info_strip.png`,
      `src="assets/images/blue_info_strip.png" style=" flex: 1; display: flex; width: 70%; height: 80%; text-align: center;position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%);"`,
      newPdfString,
    );
    newPdfString = replaceall(
      `text-align: center;`,
      `text-align: center;
      position: relative;
      height: 90;
       `,
      newPdfString,
    );
    newPdfString = replaceall(
      `justify-content: flex-end;`,
      `justify-content: flex-end;
      width: 60%;
      right: 0;
      position: relative;
      bottom: 0;
      padding-bottom: 20;
      padding-left: 20;
       `,
      newPdfString,
    );
    newPdfString = replaceall(
      `width: 187px;`,
      `width: 100%;
      height: 100%;
       `,
      newPdfString,
    );
    newPdfString = replaceall(
      `  width: 60%;`,
      `width: 187px;
      height: 90px;
      right: 0;
      top: 0;
      position: absolute;`,
      newPdfString,
    );
    newPdfString = replaceall(
      `margin-bottom: 20px;`,
      `margin-bottom: 20px;
      margin-top: 20px;
      align-text: center;
      padding-top: 10px;
      padding-left: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;`,
      newPdfString,
    );
    newPdfString = replaceall(
      `line-height: 1.6;`,
      `line-height: 1.6;
      margin-bottom: 20px;
      margin-top: 20px;`,
      newPdfString,
    );

    return (
      <>
        <style type="text/css" media="print">
          {'\
        @page { size: portrait; }\
      '}
        </style>
        <style>{`@page { margin: ${5}mm ${5}mm ${5}mm ${5}mm !important; }`}</style>
        <img src="assets/images/fines-sa-logo-white.png" alt="finesSA logo" className="nav-logo" />
        <div
          className="p-5"
          ref={componentRef}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: newPdfString }}
        />
      </>
    );
  });

  if (useInvoiceItemsQuery.isLoading) {
    return <div>loading...</div>;
  }

  return (
    <>
      <div className="flex flex-col">
        <div className="flex  mb-2">
          <div className="flex-auto p-1" />
          <div className="flex flex-col items-end gap-1">
            <TailwindButton
              onClick={() => useCreatePaymentLinkMutation.mutate()}
              className="bg-tertiary hover:bg-tertiary-dark text-white w-40"
              isLoading={useCreatePaymentLinkMutation.isLoading}
            >
              Pay Now
            </TailwindButton>
            <ReactToPrint
              documentTitle={`FinesSA-Quote-${today.getFullYear()}-${
                today.getMonth() + 1
              }-${today.getDate()}.pdf`}
              trigger={() => (
                <TailwindButton
                  onClick={() => null}
                  className="bg-secondary hover:bg-secondary-dark text-white w-40"
                >
                  Print Invoice
                </TailwindButton>
              )}
              onBeforeGetContent={async () => {
                await useCreateInvoicePrintTemplateMutation.mutateAsync();
              }}
              content={() => componentRef.current}
            />
          </div>
        </div>
        <UnpaidTableRow
          showCheckBoxes={false}
          className="h-11 md:flex"
          checked={false}
          columns={[
            <p className="font-semibold text-xl">Fine Information</p>,
            <p className="font-semibold text-xl">Fine Amount</p>,
            <p className="font-semibold text-xl">Discount</p>,
            <p className="font-semibold text-xl">Other Fees</p>,
            <p className="font-semibold text-xl">Amount Paid</p>,
            <p className="font-semibold text-xl">Amount Outstanding</p>,
            <p className="font-semibold text-xl">Icon</p>,
          ]}
        />
      </div>
      <div className="overflow-y-auto h-full">
        {_.map(useInvoiceItemsQuery.data, (offender) => (
          <div key={`offender-${offender.offenderId}`}>
            <h2 className="text-white font-medium py-5">{`${offender.offenderName} (${offender.offenderId})`}</h2>
            {_.map(offender.fines, (localFine) => (
              <UnpaidFineSummary
                showCheckBox={false}
                key={localFine.id}
                fine={localFine}
                excludeImage
              />
            ))}
          </div>
        ))}
      </div>
      <div style={{ display: 'none' }}>
        {useCreateInvoicePrintTemplateMutation.data !== null && (
          <ComponentToPrint ref={componentRef} />
        )}
      </div>
    </>
  );
};

export default ViewInvoiceScreen;
