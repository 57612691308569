import { AxiosResponse } from 'axios';
import * as qs from 'qs';

import { ReportFilterProps } from '../../../types';

import authNetworkService from '../auth-network/auth-network.service';
import adminReportsUrls from './reports.urls';

export const downloadLeadPartnerReportCsv = (
  filterProps?: ReportFilterProps,
): Promise<AxiosResponse<Blob>> => {
  const url = adminReportsUrls.leadPartnersUrl();

  return authNetworkService.get(url, {
    responseType: 'blob',
    timeout: 0,
    headers: { Accept: 'application/csv' },
    params: {
      fromDate: filterProps.fromDate,
      toDate: filterProps.toDate,
      groupByAttributes: ['UtmCampaign', 'UtmSource', 'UtmMedium'],
    },
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
  });
};

export const getGeneralReport = (filterProps?: ReportFilterProps): Promise<AxiosResponse<Blob>> => {
  const url = adminReportsUrls.generalReportUrl();

  return authNetworkService.get(url, {
    responseType: 'blob',
    timeout: 0,
    headers: { Accept: 'application/csv' },
    params: {
      fromDate: filterProps.fromDate,
      toDate: filterProps.toDate,
    },
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
  });
};

export default { downloadLeadPartnerReportCsv, getGeneralReport };
