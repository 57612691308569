import { OffenderIdentifierType } from '../enums/offender-identifier-type.enum';

export enum ProfileType {
  Individual,
  Fleet,
}

export type SignUpDataDto = {
  signUpDateTime: Date;
  signUpSource: string;
};

export type UpdateProfileRequest = {
  updateProfileType: number;
  firstName: string;
  lastName: string;
  email: string;
  identifier: string;
  cellPhoneNumber: string;
  signUpInformation: SignUpDataDto;
  communicationEmails: string[];
  isArchived: boolean;
};

export type OnboardStatus = {
  fleet: boolean;
  individual: boolean;
};

export type UserInfo = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  communicationEmails: string[];
};

export type AccountProfiles = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  individual: Individual;
  business: Business;
  offenderIdentifier: OffenderIdentifier;
  signUpInformation: SignUpDataDto;
  communicationEmails: string[];
  isArchived: boolean;
  doNotContact: boolean;
  isWhatsAppVerified: boolean;
};

export type Individual = {
  id: string;
  idNumber: string;
  familyMembers: FriendsAndFamily[];
  note: string;
  syntellExportSyncActive: boolean;
};

export type FriendsAndFamily = {
  id: string;
  firstName: string;
  lastName: string;
  offenderIdentifier: OffenderIdentifier;
  syntellExportSyncActive: boolean;
  sync: string;
};

export type OffenderIdentifier = {
  identifierType: OffenderIdentifierType;
  value: string;
};

export type Business = {
  id: string;
  note: string;
  businesses: BusinessInfo[];
};

export type BusinessInfo = {
  address: string;
  companyRegistrationNumber: string;
  id: string;
  name: string;
  vatNumber: string;
  links: Links[];
};

export type Links = {
  id: string;
  value: string;
  identifierType: OffenderIdentifierType;
  syntellExportSyncActive: boolean;
  sync: string;
};

export type EditAccountProps = {
  firstName: string;
  lastName: string;
  cellphoneNumber?: string;
  email: string;
  confirmEmail?: string;
  idNumber: string;
  identifierType: OffenderIdentifierType;
  code: string;
  cellphone: string;
  communicationEmail: string;
};

export type EditAccountUpdateDto = {
  firstName: string;
  lastName: string;
  cellphoneNumber?: string;
  email: string;
  confirmEmail?: string;
  idNumber: string;
  identifierType: OffenderIdentifierType;
  code: string;
  cellphone: string;
  communicationEmails: string[];
};

export type CreateReferral = {
  key: string;
  firstName: string;
  emailAddress: string;
};

export type Referral = {
  id: string;
  firstName: string;
  emailAddress: string;
  status: ReferralStatus;
};

export enum ReferralStatus {
  Sent = 0,
  Pending = 1,
  Accepted = 2,
  ProcessingClaim = 3,
  Claimed = 4,
  ReferredByOther = 5,
}
