import { Avatar, Card, CardContent, CircularProgress, Divider } from '@material-ui/core';
import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useHistory } from 'react-router-dom';

import { Button, SearchField } from '../../../components';
import { useDownloadFile } from '../../../hooks/global';
import { useAdminFinesData } from '../../../react-queries/admin-fines-data/admin-fines-data.queries';
import { useAdminInvoiceData } from '../../../react-queries/admin-invoices-data/admin-invoices-data.queries';
import { useAdminUsersData } from '../../../react-queries/admin-users-data/admin-users-data.queries';
import { operationsService } from '../../../services';

const AdminScreen: React.FC = () => {
  const [searchFines, setSearchFines] = useState<string>('');
  const [searchUserFullName, setSearchUserFullName] = useState<string>('');
  const [searchInvoices, setSearchInvoices] = useState<string>('');
  const history = useHistory();
  const usersData = useAdminUsersData(1, 12, searchUserFullName, '', '', '', '', '', '');
  const invoiceData = useAdminInvoiceData({
    referenceNumber: searchInvoices,
    offenderIdentifierValue: '',
    recipientName: '',
    vatNumber: '',
    address: '',
    companyRegistrationNumber: '',
    pageNum: 1,
    pageEntries: 12,
  });
  const filterOptions = useMemo(() => {
    return {
      vehicleRegistration: '',
      byNag: false,
      offenderIdentifierValue: '',
      noticeNumber: searchFines,
      pageNum: 1,
      pageEntries: 12,
    };
  }, [searchFines]);

  const fineData = useAdminFinesData(filterOptions);

  const _goToIndividualUser = (id: string) => (): void => {
    history.push(`/admin/individual/user?Id=${id}`);
  };
  const _goToIndividualFine = (id: string) => (): void => {
    history.push(`/admin/individual/fine?Id=${id}`);
  };
  const _goToIndividualInvoice = (id: string) => (): void => {
    history.push(`/admin/individual/invoice/?Id=${id}`);
  };
  const _goFineTable = () => {
    history.push('/admin/fines');
  };
  const _goToBulkTmtCapeTownUpload = () => {
    history.push('/admin/tmt-fines-bulk-upload');
  };
  const _goToWhatsAppWhitelistBulkUpload = () => {
    history.push('/admin/whatsapp-whitelist-bulk-upload');
  };
  const _goToInvoiceTable = () => {
    history.push('/admin/invoice');
  };
  const _goToUserTable = () => {
    history.push('/admin/users');
  };

  const usersCards = () => {
    if (usersData.isLoading) {
      return <CircularProgress />;
    }
    return (
      <div className=" flex flex-col m-2">
        <Card className="flex h-formThird w-cardThird shadow-2xl">
          <CardContent className="flex flex-col flex-grow">
            <div className="mb-2 font-bold">
              <p>Accounts</p>
            </div>
            <Divider />
            <div className="mb-1">
              <SearchField
                placeholder="Search"
                value={searchUserFullName}
                isLoading={usersData.isLoading}
                onChange={(value) => {
                  setSearchUserFullName(value);
                }}
              />
            </div>
            <Divider />
            <div className="overflow-auto ...">
              {!usersData.isLoading &&
                _.map(usersData.data.items, (userData) => (
                  <div key={userData.email} className="flex justify-between mb-10">
                    <div className="flex flex-row justify-center justify-items-center">
                      <Avatar className="mr-3 mt-1">
                        {userData.firstName[0]}
                        {userData.lastName[0]}
                      </Avatar>
                      <p className="flex pt-3">
                        {userData?.firstName}
                        &nbsp;{userData?.lastName}
                      </p>
                    </div>
                    <Button
                      onClick={_goToIndividualUser(userData.id)}
                      className="rounded-full mt-2"
                    >
                      View
                    </Button>
                  </div>
                ))}
            </div>
            <div className="flex mt-auto justify-center">
              <Button onClick={_goToUserTable} size="small" className="rounded-full">
                Load More
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  };

  const innerInvoicesCards = () => {
    if (invoiceData.isLoading) {
      return <CircularProgress />;
    }
    return _.map(invoiceData?.data?.items, (useInvoiceData) => (
      <div className="mb-3">
        <Card className="text-xs shadow-md">
          <CardContent>
            <div className="flex flex-row justify-between">
              <div className="flex flex-col">
                <p className="font-bold mb-2">{useInvoiceData.recipientName}</p>
                <div className="text-gray-500">
                  <p>{useInvoiceData.offenderIdentifier}</p>
                  <p>Fines paid: {useInvoiceData.finesPaid}</p>
                </div>
              </div>
              <div className="ml-10">
                <p>Paid Amount</p>
                <p>R {useInvoiceData.amountPaid}</p>
                <Button
                  onClick={_goToIndividualInvoice(useInvoiceData?.id)}
                  size="small"
                  className="mt-1 bg-secondary bg-opacity-50 text-secondary hover:bg-secondary hover:bg-opacity-50"
                >
                  Full Info
                </Button>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    ));
  };

  const invoicesCard = () => {
    return (
      <div className="flex m-2">
        <Card className=" flex w-cardThird h-formThird shadow-2xl">
          <CardContent className="flex flex-col flex-grow">
            <div className="mb-2 font-bold">
              <p>Invoices</p>
            </div>
            <Divider />
            <div className="mb-1">
              <SearchField
                placeholder="Search"
                value={searchInvoices}
                isLoading={invoiceData.isLoading}
                onChange={(value) => {
                  setSearchInvoices(value);
                }}
              />
            </div>
            <Divider />
            <div className="overflow-auto ...">{innerInvoicesCards()}</div>
            <div className="flex mt-auto justify-center">
              <Button onClick={_goToInvoiceTable} size="small" className="rounded-full">
                Load More
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  };

  const innerFinesCards = () => {
    if (fineData.isLoading) {
      return <CircularProgress />;
    }
    return _.map(fineData?.data?.items, (useFineData) => (
      <div className="mb-3">
        <Card className="text-xs shadow-md">
          <CardContent>
            <div className="flex flex-row justify-between">
              <div className="flex flex-col">
                <p className="font-bold mb-2">{useFineData.offenderIdentifier}</p>
                <p className="font-bold">{useFineData.noticeNumber}</p>
                <p>Reg No: {useFineData.vehicleRegistration}</p>
                <div className="flex flex-row justify-between">
                  <p className="">Offense Date: {useFineData.date}</p>
                  <p className="ml-8">Area: {useFineData.area}</p>
                </div>
              </div>
              <div className="border-l-2 ">
                <div className="ml-6">
                  <p>Fine Amount</p>
                  <p>R{useFineData?.amountDue}</p>
                  {useFineData.amountPaid !== useFineData.amountDue ||
                  useFineData.amountDue === 0 ? (
                    <Button
                      onClick={_goToIndividualFine(useFineData?.id)}
                      size="small"
                      className="mt-1 text-xs bg-tertiary bg-opacity-20 text-tertiary hover:bg-tertiary hover:bg-opacity-20"
                    >
                      Full Info
                    </Button>
                  ) : (
                    <Button
                      onClick={_goToIndividualFine(useFineData?.id)}
                      size="small"
                      className="mt-1 bg-total-green bg-opacity-20 text-total-green text-xs hover:bg-total-green hover:bg-opacity-20"
                    >
                      Full Info
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    ));
  };

  const finesCard = () => {
    return (
      <Card className="flex w-cardThird h-formThird m-2 shadow-2xl">
        <CardContent className="flex flex-col flex-grow ">
          <div className="mb-2 font-bold">
            <p>New Fines</p>
          </div>
          <Divider />
          <div className="mb-1">
            <SearchField
              placeholder="Search"
              value={searchFines}
              isLoading={fineData.isLoading}
              onChange={(value) => {
                setSearchFines(value);
              }}
            />
          </div>
          <Divider />

          <div className="overflow-auto ...">{innerFinesCards()}</div>
          <div className="flex justify-center mt-auto">
            <Button onClick={_goFineTable} size="small" className="rounded-full ">
              Load More
            </Button>
          </div>
        </CardContent>
      </Card>
    );
  };

  const { download, loading: isDownloadingCoctExport } = useDownloadFile({
    apiDefinition: operationsService.exportCapeTownWeeklyOffenderIdentifiers,
    onError: () => toast.error('Could not download COCT export'),
    fileName: 'COCT_Export.csv',
  });

  if (fineData.isLoading || usersData.isLoading || invoiceData.isLoading) {
    return <div>loading...</div>;
  }

  return (
    <>
      <div className="border-b border-black mt-8 flex justify-end mr-16 ml-16 space-x-2">
        <Button
          className="text-white bg-opacity-80 bg-secondary mb-2 hover:bg-blue-900"
          type="text"
          onClick={_goToWhatsAppWhitelistBulkUpload}
        >
          Upload WhatApp Whitelist Bulk
        </Button>
        <Button
          onClick={_goToBulkTmtCapeTownUpload}
          type="text"
          className="text-white bg-opacity-80 bg-secondary mb-2 hover:bg-blue-900"
        >
          Upload TMT Capetown
        </Button>
        <Button
          isLoading={isDownloadingCoctExport}
          disabled={isDownloadingCoctExport}
          onClick={download}
          type="text"
          className="text-white bg-opacity-80 bg-secondary mb-2 hover:bg-blue-900"
        >
          Export TMT Capetown
        </Button>
      </div>
      <div className="flex flex-row justify-evenly mt-10">
        {finesCard()}
        {invoicesCard()}
        {usersCards()}
      </div>
    </>
  );
};

export default AdminScreen;
