import {
  Avatar,
  Card,
  CardContent,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import Modal from 'react-modal';
import CloseIcon from '@material-ui/icons/Close';
import parsePhoneNumber from 'libphonenumber-js/max';
import toast, { Toaster } from 'react-hot-toast';

import { Delete, Edit } from '@material-ui/icons';
import { useQueryParam } from 'use-query-params';
import {
  Button,
  EditAccountForm,
  EditFamilyForm,
  AddReferralsForm,
  FormContainer,
  OnboardBusinessForm,
  OnboardIndividualForm,
} from '../..';
import { useResponsive } from '../../../hooks';
import { useIndividualFilterData, useOnboardStatus, useUserInfo } from '../../../react-queries';
import { userAuthService, profilesService, referralsService } from '../../../services';
import {
  AddFleetEntityLinksProps,
  AddProfileFleetEntityAndLinkProps,
  BusinessEntityProps,
  EditAccountProps,
  EditFleetEntityLinksProps,
  EditIndividualProps,
  EditProfileFleetEntityAndLinkProps,
  FamilyMemberProps,
  IndividualFilterData,
  CreateReferral,
  LinkProps,
  OnboardBusinessProps,
  OnboardIndividualProps,
  ReferralFormProps,
} from '../../../types';
import { useAccountProfileDetailData } from '../../../react-queries/app-account-details-data/app-account-data.queries';
import { EditUserFleetEntityForm } from '../../forms/edit-forms/edit-user-fleet-entity-form';
import { AddUserFleetEntityForm } from '../../forms/add-fleet-entity-with-links-form/add-fleet-entity-with-links-form';
import { useDeleteFleetEntityMutation } from '../../../hooks/fleet-entities/use-delete-fleet-entity-mutation';
import fleetEntitiesService from '../../../services/api/fleet-entities/fleet-entities.service';
import { OffenderIdentifierType, OffenderIdentifierTypeDescription } from '../../../enums';

const customStyles = () => {
  return {
    overlay: {
      zIndex: 1000,
      background:
        'linear-gradient(290deg, #061F40D9 0%, #031020D9 10%, #101925D9 30%, #0C0C0CD9 100%) 0% 0% no-repeat padding-box',
      boxShadow: '0px 20px 50px #3E3E3E4D',
      filter: 'blur(0px)',
      overflowY: 'auto',
    },
    content: {
      top: '50%',
      left: '50%',
      width: '100%',
      maxWidth: '770px',
      height: 'auto',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '30px',
      padding: 0,
      zIndex: 100,
      opacity: 1,
    },
  };
};

Modal.setAppElement('#root');

const referralFormInitialValues = {
  referrals: [
    {
      key: crypto.randomUUID(),
      firstName: '',
      emailAddress: '',
    } as CreateReferral,
  ],
};

export const AppLayout: React.FC = ({ children }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState('');
  const [individualAndFamilyMemberFilterData, setIndividualAndFamilyMemberFilterData] = useState<
    IndividualFilterData[]
  >([]);
  const [updateIndividual, setUpdateIndividual] = useState(false);
  const [updateBusiness, setUpdateBusiness] = useState(false);
  const onboardStatus = useOnboardStatus();
  const queryClient = useQueryClient();
  const history = useHistory();
  const userInfo = useUserInfo();
  const responsive = useResponsive();
  const [anchorElement, setAnchor] = useState<null | HTMLElement>(null);
  const [isBusiness, setIsBusiness] = useState<boolean>(
    window.location.pathname.includes('/business'),
  );
  const isOnboardedAsIndividualAndFleet =
    (onboardStatus.data?.fleet && onboardStatus.data?.individual) ?? false;
  const isFleetOnly = onboardStatus.data?.fleet && !onboardStatus.data?.individual;

  const individualFilterData = useIndividualFilterData(isOnboardedAsIndividualAndFleet);
  const userProfileData = useAccountProfileDetailData(async (result) => {
    if (result.isArchived) {
      await userAuthService.logout();
      queryClient.clear();
      history.push('/login');
    }
  });
  const [fleetEntityId] = useQueryParam<string>('id');
  const [linkIndex, SetLinkIndex] = useState<number>(0);
  const [edit, setEdit] = useState<boolean>(false);
  const [dialogQueryParam] = useQueryParam<string>('dialog');
  const [utmSource] = useQueryParam<string>('utmSource');

  const getInitialEditAccountValues = (): EditAccountProps => {
    const defaultPhoneNumber = parsePhoneNumber(`+${_.get(userInfo?.data, 'phoneNumber', '')}`);
    const code = _.get(defaultPhoneNumber, 'countryCallingCode', '27');
    const cellNum = _.get(defaultPhoneNumber, 'nationalNumber', '');
    return {
      firstName: `${_.get(userInfo.data, 'firstName', '')}`,
      lastName: `${_.get(userInfo.data, 'lastName', '')}`,
      code: `${code}`,
      cellphone: `${cellNum}`,
      email: userInfo.data?.email ?? '',
      confirmEmail: userInfo.data?.email ?? '',
      idNumber:
        individualFilterData?.data === undefined || individualFilterData?.data?.isNullOrEmpty()
          ? ''
          : individualFilterData.data[0]?.idNumber,
      identifierType:
        individualFilterData?.data === undefined || individualFilterData?.data?.isNullOrEmpty()
          ? null
          : individualFilterData?.data[0]?.identifierType,
      communicationEmail: userInfo.data?.communicationEmails[0] ?? '',
    };
  };

  useEffect(() => {
    setIsBusiness(window.location.pathname.includes('/business'));
  }, [window.location.pathname]);

  // 'Me' isn't a great test but fixes weird behaviour.
  //  Need to add an API call to fetch family members exclusively and then rather use that
  const familyMemberFilterData =
    individualAndFamilyMemberFilterData.filter((filterData) => {
      return filterData.fullName !== 'Me';
    }) ?? [];

  const onboardIndividualFormInitialValues: OnboardIndividualProps = {
    idNumber: !_.isEmpty(individualAndFamilyMemberFilterData)
      ? _.get(individualAndFamilyMemberFilterData[0], 'idNumber', '')
      : '',
    idType: 'ID',
    identifierType:
      individualAndFamilyMemberFilterData[0]?.identifierType ?? OffenderIdentifierType.SaIdNumber,
    confirmIdNumber: !_.isEmpty(individualAndFamilyMemberFilterData)
      ? _.get(individualAndFamilyMemberFilterData[0], 'idNumber', '')
      : '',
    familyMembers:
      familyMemberFilterData?.length === 0
        ? [
            {
              firstName: '',
              lastName: '',
              idNumber: '',
              identifierType: OffenderIdentifierType.SaIdNumber,
            } as FamilyMemberProps,
          ]
        : familyMemberFilterData.map((i) => {
            return {
              firstName: i.firstName,
              lastName: i.lastName,
              idNumber: i.idNumber,
              identifierType: i.identifierType,
            } as FamilyMemberProps;
          }),
  };

  const onboardBusinessInitialValues: OnboardBusinessProps = {
    businessEntities: [
      {
        entityName: '',
        address: '',
        vatNo: '',
        companyRegistration: '',
        links: [{ value: '', identifierType: OffenderIdentifierType.Brn }],
      },
    ],
  };

  const editFriendsAndFamilySubmission = (formData: EditIndividualProps) => {
    const request = _.cloneDeep(formData);
    if (
      formData.familyMembers
        .filter((fm) => fm.firstName !== '' || fm.lastName !== '' || fm.idNumber !== '')
        .isNullOrEmpty()
    )
      request.familyMembers = [];

    return profilesService
      .editFamilyAndFriends(request)
      .then(() => setUpdateIndividual(!updateIndividual));
  };

  const signUpIndividualSubmit = (formData: OnboardIndividualProps) => {
    return profilesService
      .onboardIndividual(formData)
      .then(() => setUpdateIndividual(!updateIndividual));
  };

  const createReferralsSubmission = (formData: ReferralFormProps) => {
    return referralsService.createBulkReferrals(formData.referrals, utmSource);
  };

  const editFleetEntityLinks: EditFleetEntityLinksProps[] =
    userProfileData?.data?.business?.businesses != null &&
    _.map(userProfileData?.data?.business?.businesses[linkIndex]?.links, (link) => ({
      id: link.id,
      value: link?.value,
      identifierType: link?.identifierType,
    }));

  const addFleetEntityLinks: AddFleetEntityLinksProps[] = [
    {
      id: crypto.randomUUID(),
      value: '',
      identifierType: OffenderIdentifierType.Brn,
    },
  ];
  const editFleetEntityInitialValues: EditProfileFleetEntityAndLinkProps = userProfileData?.data
    ?.business?.businesses != null && {
    entityName: userProfileData?.data?.business?.businesses[linkIndex]?.name,
    address: userProfileData?.data?.business?.businesses[linkIndex]?.address,
    vatNumber: userProfileData?.data?.business?.businesses[linkIndex]?.vatNumber,
    companyRegistration:
      userProfileData?.data?.business?.businesses[linkIndex]?.companyRegistrationNumber,
    links: editFleetEntityLinks,
  };
  const addFleetEntityInitialValues: AddProfileFleetEntityAndLinkProps = {
    entityName: '',
    address: '',
    vatNumber: '',
    companyRegistration: '',
    links: addFleetEntityLinks,
  };

  const submitEditFleetEntities = (formData: EditProfileFleetEntityAndLinkProps) => {
    return fleetEntitiesService.editUserFleetEntities(fleetEntityId, formData);
  };

  const addProfileFleetEntity = (formData: AddProfileFleetEntityAndLinkProps) => {
    return fleetEntitiesService.addProfileFleetEntities(formData);
  };

  const signUpBusinessSubmission = (formData: OnboardBusinessProps) => {
    const data: BusinessEntityProps[] = [];

    _.forEach(formData.businessEntities, (entity) => {
      const links: LinkProps[] = [];
      _.forEach(entity.links, (link) => {
        links.push({ value: link.value, identifierType: link.identifierType });
      });

      data.push({
        entityName: entity.entityName,
        address: entity.address,
        vatNo: entity.vatNo,
        companyRegistration: entity.companyRegistration,
        links,
      });
    });
    const submitData: OnboardBusinessProps = { businessEntities: data };
    return profilesService.onboardBusiness(submitData).then(() => {
      setUpdateBusiness(!updateBusiness);
      onboardStatus.refetch();
    });
  };

  const openModal = (type?: string) => {
    setModalType(type);
    setIsOpen(true);
    setAnchor(null);
  };

  useEffect(() => {
    if (dialogQueryParam && dialogQueryParam === 'referrals') {
      openModal('referrals');
    }
  }, []);

  const editFriendsAndFamilySuccess = () => {
    toast.success('Family & Friends Successfully Updated', { duration: 2000 });
    queryClient.invalidateQueries('filterData');
    setIsOpen(false);
    history.push('/individual');
  };

  const onboardIndividualSuccess = () => {
    setIsOpen(false);
    onboardStatus.refetch();
    history.push('/individual');
  };

  const editBusinessesSuccess = () => {
    queryClient.invalidateQueries('businessEntities');
    queryClient.invalidateQueries('AccountProfileDetailData');
    queryClient.invalidateQueries('businessFines');
    setIsOpen(false);
    setEdit(false);
    openModal('business');
  };

  const addBusinessesSuccess = () => {
    queryClient.invalidateQueries('businessEntities');
    queryClient.invalidateQueries('AccountProfileDetailData');
    setIsOpen(false);
    setEdit(false);
    openModal('business');
  };

  const createReferralsSuccess = () => {
    toast.success('Referral invite(s) sent', { duration: 2000 });
    queryClient.invalidateQueries('userReferrals');
    setIsOpen(false);
    window.location.href = `/referral-success${utmSource ? `?utmSource=${utmSource}` : ''}`;
  };

  useEffect(() => {
    if (onboardStatus?.data?.individual) {
      individualFilterData.refetch().then((result) => {
        setIndividualAndFamilyMemberFilterData(result.data);
      });
    }
  }, [updateIndividual]);

  const _signOut = () => {
    return userAuthService.logout().then(() => {
      queryClient.clear();
      history.push('/login');
    });
  };

  const _openUser = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchor(event.currentTarget);
  };

  const _aboutUs = (): void => {
    window.open('https://www.finessa.co.za/about-us.php', '_blank');
  };

  const _services = (): void => {
    window.open('https://www.finessa.co.za/services.php', '_blank');
  };

  const closeModal = () => {
    setIsOpen(false);
    setEdit(false);
  };

  const editAccountSubmission = (formData: EditAccountProps) => {
    const phoneNumber = parsePhoneNumber(`+${formData.code} ${formData.cellphone}`);
    const newFormData = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      cellphoneNumber: phoneNumber.formatInternational(),
      email: formData.email,
      code: formData.code,
      cellphone: formData.cellphone,
      idNumber: formData.idNumber,
      identifierType: formData.identifierType,
      communicationEmails: [formData.communicationEmail],
    };
    return profilesService.editAccount(newFormData);
  };

  const editAccountSuccess = () => {
    toast.success('Details Successfully Updated', { duration: 2000 });
    queryClient.invalidateQueries('userInfo');
    queryClient.invalidateQueries('filterData');
    setIsOpen(false);
  };

  const editAccountFail = () => {
    toast.error('Details Not Successfully Updated', { duration: 2000 });
    setTimeout(function waitForToast() {
      setIsOpen(false);
      setEdit(false);
    }, 2500);
  };

  const createReferralFail = () => {
    toast.error('Referral(s) could not be invited', { duration: 3000 });
  };

  const switchAccounts = () => {
    if (!onboardStatus.data) {
      onboardStatus.refetch();
    } else if (_.get(onboardStatus, 'data.individual') && _.get(onboardStatus, 'data.fleet')) {
      setIsBusiness(!isBusiness);
    }
  };

  const scrollToHome = () => {
    if (_.get(onboardStatus, 'data.individual')) {
      setIsBusiness(true);
    }
    if (_.get(onboardStatus, 'data.fleet')) {
      setIsBusiness(false);
    }
    if (onboardStatus.data.fleet && onboardStatus.data.individual) {
      history.push('/individual');
    } else if (onboardStatus.data.fleet) {
      history.replace('/business');
    } else {
      history.push('/individual');
    }
  };

  useEffect(() => {
    if (!onboardStatus.data) {
      onboardStatus.refetch();
    }
  }, []);

  useEffect(() => {
    if (_.get(onboardStatus, 'data.individual') && _.get(onboardStatus, 'data.fleet')) {
      if (isBusiness) {
        history.push(`/business${history.location.search}`);
      } else {
        history.push(`/individual${history.location.search}`);
      }
    }
  }, [isBusiness]);

  const _editEntity = (index: number) => () => {
    setEdit(true);
    SetLinkIndex(index);
    history.push(`business?id=${userProfileData.data.business.businesses[index].id}`);
  };

  const useDeleteEntityMutation = useDeleteFleetEntityMutation(
    (entityId) =>
      userProfileData.data.business.businesses.find((businessInfo) => businessInfo.id === entityId)
        .links.length > 0,
    () => {
      queryClient.invalidateQueries('businessEntities');
      queryClient.invalidateQueries('AccountProfileDetailData');
      queryClient.invalidateQueries('businessFines');
    },
    () => {
      toast.error('Cannot delete a business that still has a BRN or ProxyID attached to it');
    },
  );

  const renderData = () =>
    _.map(userProfileData?.data?.business?.businesses, (FleetEntity, index) => {
      return (
        <Card className={`my-3 ${FleetEntity.id}`} key={FleetEntity.id}>
          <CardContent className="flex mt-1.5 " key={`${FleetEntity.id}-card-inner`}>
            <div className="p-2">
              <img src="assets/icons/id-card-icon.png" alt="companyReg" />
            </div>
            <div className="text-left flex flex-col justify-center">
              <p className="text-xs font-bold text-text-grey">{FleetEntity.name}</p>
              <p className="text-xs font-bold text-text-grey">{`Address: ${FleetEntity.address}`}</p>
              <p className="text-xs font-bold text-text-grey">{`Vat No: ${FleetEntity.vatNumber}`}</p>
              <p className="text-xs font-bold text-text-grey">{`Company Reg: ${FleetEntity.companyRegistrationNumber}`}</p>

              {_.map(FleetEntity.links, (links) => {
                return links != null ? (
                  <p
                    className="text-xs font-thin text-text-grey"
                    key={crypto.randomUUID()}
                  >{`Entity Links: ${OffenderIdentifierTypeDescription[links.identifierType]}: ${
                    links.value
                  }`}</p>
                ) : (
                  <></>
                );
              })}
            </div>
            <div className="flex flex-1" />

            <IconButton onClick={_editEntity(index)} className="m-1 hover:bg-transparent">
              <Edit className="h-4 w-4" />
            </IconButton>
            {userProfileData.data.business.businesses.length > 1 ? (
              <IconButton
                onClick={() =>
                  useDeleteEntityMutation.mutate(userProfileData.data.business.businesses[index].id)
                }
                className="m-1 hover:bg-transparent"
              >
                <Delete className="h-4 w-4" />
              </IconButton>
            ) : null}
          </CardContent>
        </Card>
      );
    });
  const outerCard = () => {
    return (
      <div className="flex m-2">
        <Card
          elevation={0}
          className="flex w-full h-formThird rounded-lg border-none bg-form-background"
        >
          <CardContent className="flex flex-col flex-grow">
            <div className="flex flex-row justify-between">
              <div className=" mt-4 font-bold text-lg">
                <p>Business Entities</p>
              </div>
              <Button
                onClick={() => openModal('add-entity')}
                className="flex bg-primary hover:bg-primary-dark text-white text-base self-center mt-3"
                type="text"
              >
                + Add
              </Button>
            </div>
            <div className=" mt-3 overflow-auto">{renderData()}</div>
          </CardContent>
        </Card>
      </div>
    );
  };

  return (
    <>
      <Toaster position="top-center" />
      <div className="bg-auth-background bg-cover h-screen absolute bottom-0 top-0 left-0 right-0 -z-10" />
      <div className="flex flex-1 flex-col h-screen">
        <nav className="px-4 justify-between bg-secondary h-20 flex bg-opacity-90">
          <ul className="h-full">
            <li className="h-full flex py-3">
              <button onClick={scrollToHome} type="button">
                <img
                  src="assets/images/fines-sa-logo-white.png"
                  alt="finesSA logo"
                  className="nav-logo"
                />
              </button>
            </li>
          </ul>
          <ul className="h-full flex">
            {_.get(onboardStatus, 'data.individual') && _.get(onboardStatus, 'data.fleet') && (
              <Button
                className="flex items-center my-0 text-white w-25  md:w-80"
                onClick={switchAccounts}
                type="text"
              >
                {isBusiness ? 'Switch to Personal Account' : 'Switch to Business/Fleet Account'}
              </Button>
            )}
            {!responsive.isLarge ? (
              <>
                <Button
                  className="flex items-center my-0 text-white md:w-55 pr-2"
                  type="text"
                  size="small"
                  onClick={() => openModal('account')}
                >
                  <img src="/assets/icons/lock-white-icon.png" alt="" />
                  &nbsp;&nbsp;Edit Account Details
                </Button>
                {!isBusiness && onboardStatus.data?.individual && (
                  <Button
                    className="flex items-center my-0 text-white md:w-55 pr-2 ml-2"
                    type="text"
                    size="small"
                    onClick={() => {
                      setUpdateIndividual(!updateIndividual);
                      openModal('family');
                    }}
                  >
                    <img src="/assets/icons/pencil-white-icon.png" alt="" />
                    &nbsp;&nbsp;Add/Edit Family &amp; Friends
                  </Button>
                )}
                {isBusiness && (
                  <Button
                    className="flex items-center my-0 text-white md:w-55 pr-2 ml-2"
                    type="text"
                    size="small"
                    onClick={() => {
                      setUpdateBusiness(!updateBusiness);
                      openModal('business');
                    }}
                  >
                    <img className="w-4" src="/assets/icons/truck-filled-icon.png" alt="" />
                    &nbsp;&nbsp;Add/Edit Businesses
                  </Button>
                )}
                {!onboardStatus.data?.individual && (
                  <Button
                    className="flex items-center my-0 text-white md:w-55 pr-2 ml-2"
                    type="text"
                    size="small"
                    onClick={() => {
                      setUpdateBusiness(!updateBusiness);
                      openModal('add-individual-account');
                    }}
                  >
                    <img src="/assets/icons/user-filled-icon.png" alt="" className="w-3" />
                    &nbsp;&nbsp;Create Individual Account
                  </Button>
                )}
                {!onboardStatus.data?.fleet && (
                  <Button
                    className="flex items-center my-0 text-white md:w-55 pr-2 ml-2"
                    type="text"
                    size="small"
                    onClick={() => {
                      setUpdateBusiness(!updateBusiness);
                      openModal('add-business-account');
                    }}
                  >
                    <img src="/assets/icons/truck-filled-icon.png" alt="" className="w-4" />
                    &nbsp;&nbsp;Create Business Account
                  </Button>
                )}
                <Button
                  className="flex items-center my-0 text-white md:w-55 pr-2"
                  type="text"
                  size="small"
                  onClick={() => openModal('referrals')}
                >
                  <img
                    className="h-[14px]"
                    src="/assets/icons/user-white-icon.png"
                    alt="referrals"
                  />
                  &nbsp;&nbsp;<b>Referrals</b>
                </Button>
              </>
            ) : null}
          </ul>
          <ul className="h-full">
            {userInfo.isLoading ? (
              <></>
            ) : (
              <li className="h-full flex">
                <Button
                  className="flex flex-row items-center shadow-xl m-1"
                  onClick={_openUser}
                  type="outlined"
                >
                  {responsive.isMobile ? (
                    <img src="/assets/icons/hamburger-menu-icon.png" alt="menu" />
                  ) : (
                    <>
                      <Avatar className="mr-3">{`${userInfo.data.firstName[0]}${userInfo.data.lastName[0]}`}</Avatar>
                      <div className="flex flex-col items-start">
                        <p className="text-white">{`${userInfo.data.firstName} ${userInfo.data.lastName}`}</p>
                        <p className="text-white font-thin">{userInfo.data.email}</p>
                      </div>
                    </>
                  )}
                </Button>
                <Menu
                  anchorEl={anchorElement}
                  open={!!anchorElement}
                  onClose={() => setAnchor(null)}
                  PopoverClasses={{ paper: 'bg-secondary' }}
                >
                  <div className="flex flex-row px-3">
                    <Avatar className="mr-3">{`${userInfo.data.firstName[0]}${userInfo.data.lastName[0]}`}</Avatar>
                    <div className="flex flex-col items-start">
                      <p className="text-white">{`${userInfo.data.firstName} ${userInfo.data.lastName}`}</p>
                      <p className="text-white font-thin">{userInfo.data.email}</p>
                    </div>
                  </div>
                  <MenuItem>
                    <img src="/assets/icons/user-white-icon.png" alt="user" />
                    <Typography className="text-white font-thin px-3">{`${userInfo.data.firstName} ${userInfo.data.lastName}`}</Typography>
                  </MenuItem>
                  <MenuItem>
                    <img src="/assets/icons/mail-white-icon.png" alt="mail" />
                    <Typography className="text-white font-thin px-3">
                      {userInfo.data.email}
                    </Typography>
                  </MenuItem>
                  <MenuItem>
                    <img src="/assets/icons/phone-white-icon.png" alt="phone" />
                    <Typography className="text-white font-thin px-3">
                      {userInfo.data.phoneNumber}
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={() => openModal('account')}>
                    <img src="/assets/icons/lock-white-icon.png" alt="editAccount" />
                    <Typography className="text-white font-thin px-3">
                      Edit Account Details
                    </Typography>
                  </MenuItem>
                  {!isBusiness && onboardStatus.data?.individual && (
                    <MenuItem
                      onClick={() => {
                        setUpdateIndividual(!updateIndividual);
                        openModal('family');
                      }}
                    >
                      <img src="/assets/icons/pencil-white-icon.png" alt="" />
                      <Typography className="text-white font-thin px-3">
                        Add/Edit Family & Friends
                      </Typography>
                    </MenuItem>
                  )}
                  {isBusiness && (
                    <MenuItem
                      onClick={() => {
                        setUpdateBusiness(!updateBusiness);
                        openModal('business');
                      }}
                    >
                      <img src="/assets/icons/truck-filled-icon.png" alt="" className="w-4" />
                      <Typography className="text-white font-thin px-3">
                        Add/Edit Businesses
                      </Typography>
                    </MenuItem>
                  )}
                  {!onboardStatus.data?.individual && (
                    <MenuItem
                      onClick={() => {
                        setUpdateBusiness(!updateBusiness);
                        openModal('add-individual-account');
                      }}
                    >
                      <img src="/assets/icons/user-filled-icon.png" alt="" className="w-3" />
                      <Typography className="text-white font-thin px-3">
                        Create Individual Account
                      </Typography>
                    </MenuItem>
                  )}
                  {!onboardStatus.data?.fleet && (
                    <MenuItem
                      onClick={() => {
                        setUpdateBusiness(!updateBusiness);
                        openModal('add-business-account');
                      }}
                    >
                      <img src="/assets/icons/truck-filled-icon.png" alt="" className="w-4" />
                      <Typography className="text-white font-thin px-3">
                        Create Business Account
                      </Typography>
                    </MenuItem>
                  )}
                  <MenuItem
                    onClick={() => {
                      openModal('referrals');
                    }}
                  >
                    <img src="/assets/icons/user-white-icon.png" alt="" className="w-4" />
                    <Typography className="text-white font-thin px-3">Referrals</Typography>
                  </MenuItem>
                  <MenuItem onClick={_signOut}>
                    <img src="/assets/icons/log-out-white-icon.png" alt="" />
                    <Typography className="text-white font-thin px-3">Logout</Typography>
                  </MenuItem>
                </Menu>
              </li>
            )}
          </ul>
        </nav>
        {responsive.isMobile ? (
          <div className="py-4 inline-block overflow-y-auto">
            <div className="flex flex-1 items-center justify-center mb-3">{children}</div>
            <div className="border-t-2 border-text-grey flex flex-col px-6">
              <p className="footer-text">Copyright © {new Date().getFullYear()} FinesSA</p>
              <Button onClick={_aboutUs} type="text" className="footer-text">
                About Us
              </Button>
              <Button onClick={_services} type="text" className="footer-text">
                Services
              </Button>
            </div>
          </div>
        ) : (
          <>
            <div className="py-4 xs:inline-block xs:overflow-y-auto h-full">
              <div className="flex flex-1 items-center justify-center min-h-full h-full">
                {children}
              </div>
            </div>
            <div className="h-20 bottom-0 border-t-2 border-text-grey justify-between flex px-6">
              <p className="footer-text">Copyright © {new Date().getFullYear()} FinesSA</p>
              <div className="flex">
                <Button onClick={_aboutUs} type="text" className="footer-text">
                  About Us
                </Button>
                <Button onClick={_services} type="text" className="footer-text">
                  Services
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles()}
        contentLabel="Mobile Modal"
      >
        <div className="flex h-full">
          <div className="absolute right-5 top-2">
            <button onClick={closeModal} type="button">
              <CloseIcon className="text-text-grey" />
            </button>
          </div>
          <FormContainer className="flex-1 w-full h-full">
            {modalType === 'account' && (
              <EditAccountForm
                initialValues={getInitialEditAccountValues()}
                submitForm={editAccountSubmission}
                onSuccess={editAccountSuccess}
                onFailure={editAccountFail}
                editAccount
                isFleetOnly={isFleetOnly}
              />
            )}
            {modalType === 'family' && (
              <EditFamilyForm
                initialValues={onboardIndividualFormInitialValues}
                submitForm={editFriendsAndFamilySubmission}
                onSuccess={editFriendsAndFamilySuccess}
                onFailure={editAccountFail}
                editFamily
              />
            )}
            {modalType === 'referrals' && (
              <AddReferralsForm
                initialValues={referralFormInitialValues}
                submitForm={createReferralsSubmission}
                onSuccess={createReferralsSuccess}
                onFailure={createReferralFail}
              />
            )}
            {modalType === 'business' && (
              <>
                {!edit ? (
                  <>
                    <div>{outerCard()}</div>
                  </>
                ) : (
                  <div className="">
                    <EditUserFleetEntityForm
                      initialValues={editFleetEntityInitialValues}
                      onFailure={editAccountFail}
                      onSuccess={editBusinessesSuccess}
                      submitForm={submitEditFleetEntities}
                      entityLinkIndex={linkIndex}
                      fleetEntityId={fleetEntityId}
                      setEdit={setEdit}
                    />
                  </div>
                )}
              </>
            )}
            {modalType === 'add-entity' && (
              <AddUserFleetEntityForm
                initialValues={addFleetEntityInitialValues}
                onFailure={editAccountFail}
                onSuccess={addBusinessesSuccess}
                submitForm={addProfileFleetEntity}
                openModal={openModal}
              />
            )}
            {modalType === 'add-individual-account' && (
              <OnboardIndividualForm
                initialValues={onboardIndividualFormInitialValues}
                submitForm={signUpIndividualSubmit}
                onSuccess={onboardIndividualSuccess}
                onFailure={editAccountFail}
              />
            )}
            {modalType === 'add-business-account' && (
              <OnboardBusinessForm
                initialValues={onboardBusinessInitialValues}
                onFailure={editAccountFail}
                onSuccess={editBusinessesSuccess}
                submitForm={signUpBusinessSubmission}
              />
            )}
            <div className="flex flex-1 flex-col" />
          </FormContainer>
        </div>
      </Modal>
    </>
  );
};
