import { AxiosResponse } from 'axios';
import * as qs from 'qs';
import { ServiceResult, NextCallDto } from '../../../types';
import authNetworkService from '../auth-network/auth-network.service';
import adminDailyCallsUrls from './admin-daily-calls.urls';

export const getAllDailyCallsContent = (
  callCentreAgentId: string = null,
): Promise<AxiosResponse<Blob>> => {
  const url = adminDailyCallsUrls.getBaseContentUrl();

  return authNetworkService.get(url, {
    responseType: 'blob',
    timeout: 0,
    headers: { Accept: 'application/csv' },
    params: {
      callCentreAgentId,
    },
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
  });
};

const getAgentNextCall = async (): Promise<NextCallDto> => {
  const url = adminDailyCallsUrls.dailyCallsUrl();
  const response = await authNetworkService.get<ServiceResult<NextCallDto>>(url);
  return response.data.data[0];
};

const repopulateDailyCalls = () => {
  const url = adminDailyCallsUrls.repopulateDailyCallsUrl();
  return authNetworkService.post(url, null, { timeout: 0 }).catch((error) => {
    return Promise.reject(error);
  });
};

export default {
  getAllDailyCallsContent,
  getAgentNextCall,
  repopulateDailyCalls,
};
