import React from 'react';
import DateTimePicker from 'react-datetime-picker';
import { Formik, FormikHelpers } from 'formik';
import _ from 'lodash';

import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-hot-toast';
import { Button, FormTextField } from '../../atoms';
import {
  EditFineFormMeta,
  EditFineProps,
  ErrorObject,
  FineStatus,
  IBaseFormPropsWithMeta,
  NoticeType,
} from '../../../types';
import adminFinesService from '../../../services/api/admin-fines/admin-fines.service';

export const EditFineForm: React.FC<IBaseFormPropsWithMeta<EditFineProps, EditFineFormMeta>> = ({
  initialValues,
  submitForm,
  onSuccess,
  meta,
  onFailure,
}) => {
  const _handleFormSubmitError = (error: ErrorObject, actions: FormikHelpers<EditFineProps>) => {
    onFailure();
    actions.setSubmitting(false);
    const apiErrors = error.errors;
    if (!_.isEmpty(apiErrors)) {
      actions.setFieldError('vehicleRegistration', apiErrors.vehicleRegistration);
      actions.setFieldError('noticeNumber', apiErrors.noticeNumber);
      actions.setFieldError('offenceDate', apiErrors.offenceDate);
      actions.setFieldError('municipality', apiErrors.municipality);
      actions.setFieldError('offenceLocation', apiErrors.offenceLocation);
      actions.setFieldError('offenceDescription', apiErrors.offenceDescription);
      actions.setFieldError('manualDiscount', apiErrors.manualDiscount);
    }
  };

  const _handleSubmission = (formData: EditFineProps, actions: FormikHelpers<EditFineProps>) => {
    submitForm(formData)
      .then(() => {
        onSuccess();
      })
      .catch((error: ErrorObject) => {
        _handleFormSubmitError(error, actions);
      })
      .finally(() => actions.setSubmitting(false));
  };
  const queryClient = useQueryClient();

  const useSetToUnpaidMutation = useMutation(
    'move-paid-through-other-avenue-to-unpaid',
    adminFinesService.moveToUnpaid,
    {
      onSuccess: () => {
        toast.success('Successfully moved to unpaid');
        queryClient.invalidateQueries('paged-profile-fines');
      },
      onError: (e: ErrorObject) => {
        toast.success(e.detail);
      },
    },
  );

  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur
      validateOnChange={false}
      onSubmit={_handleSubmission}
      enableReinitialize
    >
      {({
        handleSubmit,
        isSubmitting,
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleReset,
        setFieldValue,
      }) => {
        const _setDate = (e) => {
          setFieldValue('offenceDate', e);
        };

        return (
          <form
            onReset={handleReset}
            onSubmit={handleSubmit}
            className="flex flex-auto flex-col gap-3 py-1 mx-7"
          >
            <p className="flex justify-center auth-heading text-center">Edit Fine Details</p>

            <>
              <p className="FormTextField text-xs">Vehicle Registration: </p>
              <FormTextField
                variableName="vehicleRegistration"
                placeholder="Vehicle Registration"
                values={values}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched}
                errors={errors}
                required
                className=" self-center w-96"
              />
            </>
            <p className="FormTextField text-xs">OffenceDate: </p>
            <DateTimePicker
              clearIcon={null}
              format="y-MM-dd HH:mm"
              onChange={_setDate}
              value={values.offenceDate}
              disableClock
            />

            <p className="FormTextField text-xs">Municipality: </p>
            <FormTextField
              variableName="municipality"
              placeholder="Municipality"
              values={values}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched}
              errors={errors}
              required
              className="w-96"
            />
            <p className="FormTextField text-xs">Offence Location: </p>
            <FormTextField
              variableName="offenceLocation"
              placeholder="Offence Location"
              values={values}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched}
              errors={errors}
              required
              className="w-96"
            />
            <p className="FormTextField text-xs">Offence Description: </p>
            <FormTextField
              variableName="offenceDescription"
              placeholder="Offence Description"
              values={values}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched}
              errors={errors}
              required
              className="w-96"
            />

            <p className="FormTextField text-xs">Notice Type: </p>
            <select
              name="noticeType"
              value={values.noticeType}
              onChange={(e) => setFieldValue('noticeType', Number(e.target.value))}
            >
              <option value={NoticeType.Notice}>Notice</option>
              <option value={NoticeType.Summons}>Summons</option>
              <option value={NoticeType.Warrant}>Warrant</option>
              <option value={NoticeType.CourtesyLetter}>CourtesyLetter</option>
              <option value={NoticeType.EnforcementOrder}>EnforcementOrder</option>

              {meta.noticeType === NoticeType.Unknown && (
                <option value={NoticeType.Unknown}>Unknown</option>
              )}
            </select>

            <>
              <p className="FormTextField text-xs">Amount Due: </p>
              <FormTextField
                type="text"
                variableName="amountDue"
                placeholder="Amount Due"
                values={values}
                disabled={
                  meta.role !== 'SuperAdministrator' &&
                  meta.role !== 'Consultant' &&
                  meta.role !== 'CallCenterManager'
                }
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched}
                errors={errors}
                required
                className="w-96"
              />
            </>
            <p className="FormTextField text-xs">Service Fee: </p>
            <FormTextField
              type="text"
              variableName="serviceFee"
              placeholder="Service Fee"
              values={values}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched}
              errors={errors}
              required
              className="w-96"
            />

            <p className="FormTextField text-xs">Manual Discount: </p>
            <FormTextField
              type="text"
              variableName="manualDiscount"
              placeholder="Manual Discount"
              values={values}
              disabled={meta.isNag}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched}
              errors={errors}
              required
              className="w-96"
            />
            {(meta.status === FineStatus.PaidThroughOtherAvenue ||
              meta.status === FineStatus.ContestedWithMunicipality) && (
              <Button
                isLoading={useSetToUnpaidMutation.isLoading}
                onClick={() => useSetToUnpaidMutation.mutate(meta.id)}
                className="bg-secondary hover:bg-secondary-dark text-white text-base self-center mt-7"
              >
                Move to Unpaid
              </Button>
            )}

            <Button
              isLoading={isSubmitting}
              onClick={handleSubmit}
              className="w-4/5 bg-secondary hover:bg-secondary-dark text-white text-base self-center my-2"
            >
              Edit Fine
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};
