import React, { useEffect, useState } from 'react';
import { ColumnDef, getCoreRowModel, Row, useReactTable } from '@tanstack/react-table';
import moment from 'moment';
import { useQuery, useQueryClient } from 'react-query';
import { Delete, Edit } from '@material-ui/icons';
import { FineDetailDto, FinePaymentStatus, Guid } from '../../types';
import { IndeterminateCheckbox } from '../../components';
import { usePagination } from '../global';
import { useFinePaymentStatus } from './use-fine-payment-status';
import { useVehicleRegistrations } from './use-vehicle-registrations';
import { DropdownSelection } from './types';
import { adminProfilesService } from '../../services';
import { useNoticeNumberSearch } from './use-notice-number-search';
import { demeritPointsFormatter } from '../../helpers';

const useProfileFinesTable = ({
  onEdit,
  onDelete,
  useOffenderIdentifiers,
  profileId,
}: {
  onEdit: (row: Row<FineDetailDto>) => void;
  onDelete: (row: Row<FineDetailDto>) => void;
  useOffenderIdentifiers: () => DropdownSelection;
  profileId: Guid;
}) => {
  const queryClient = useQueryClient();
  const [selectedFines, setSelectedFines] = useState<Record<Guid, boolean>>({});
  const { pagination, setPagination } = usePagination();
  const [onlyNags, setOnlyNags] = useState<boolean | undefined>(false);

  const noticeNumberSearch = useNoticeNumberSearch();
  const finePaymentStatus = useFinePaymentStatus();
  const offenderIdentifiers = useOffenderIdentifiers();
  const vehicleRegistrations = useVehicleRegistrations(
    offenderIdentifiers.allValues,
    offenderIdentifiers.isReady,
  );

  useEffect(() => {
    return () => {
      queryClient.removeQueries({ queryKey: ['paged-profile-fines'] });
    };
  }, []);

  const finesQuery = useQuery(
    [
      'paged-profile-fines',
      profileId,
      pagination,
      offenderIdentifiers.selected,
      vehicleRegistrations.selected,
      finePaymentStatus.status,
      onlyNags,
      noticeNumberSearch,
    ],
    () =>
      adminProfilesService.getPaginatedProfileFines(profileId, {
        filter: {
          offenderIdentifierValues: offenderIdentifiers.selectedForQuery,
          vehicleRegistrations: vehicleRegistrations.selectedForQuery,
          noticeNumber: noticeNumberSearch.noticeNumberSearch,
          onlyNag: onlyNags,
          paymentStatus: finePaymentStatus.status,
        },
        orderBy: '',
        pageEntries: pagination.pageSize,
        pageNumber: pagination.pageIndex + 1,
      }),
    {
      enabled: offenderIdentifiers.isReady && vehicleRegistrations.isReady,
    },
  );

  const columns = React.useMemo<ColumnDef<FineDetailDto>[]>(() => {
    const columnDef = [
      {
        id: 'select',
        header: ({ table }) => (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              footer: (props) => props.column.id,
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div className="px-1">
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
      },
      {
        header: 'NoticeNumber',
        accessorKey: 'noticeNumber',
        cell: (info) => info.getValue(),
      },
      {
        header: 'Reg No',
        accessorKey: 'vehicleRegistration',
      },
      {
        header: 'Reason',
        accessorKey: 'reason',
        size: 80,
      },
      {
        header: 'Area',
        accessorKey: 'area',
      },
      {
        header: 'Offence Date',
        accessorFn: (row) => moment(row.date).format('DD-MM-YYYY HH:mm'),
      },
      {
        header: 'Demerit Points',
        accessorFn: (row) => demeritPointsFormatter(row.demeritPoints),
      },
      {
        header: 'Fine Amnt',
        accessorKey: 'fineAmount',
      },
      {
        header: 'Discount',
        accessorKey: 'discount',
      },
      {
        header: 'Warrant/Aarto Fee',
        accessorKey: 'aartoFee',
      },
      {
        header: 'Enforcement Order',
        accessorKey: 'enforcementOrder',
      },
      {
        header: 'Service Fee',
        accessorKey: 'serviceFee',
      },
      {
        header: 'Amnt Paid',
        accessorKey: 'amountPaid',
      },
      {
        header: 'Total Due',
        accessorKey: 'total',
      },
      {
        header: 'Status',
        accessorKey: 'status',
      },
      {
        header: 'Action',
        cell: ({ row }) => (
          <div className="flex  justify-around items-center">
            <button type="button" className="hover:bg-gray-200" onClick={() => onEdit(row)}>
              <Edit className="text-base" fontSize="medium" />
            </button>
            <button type="button" className="hover:bg-gray-200" onClick={() => onDelete(row)}>
              <Delete className="text-base" fontSize="medium" />
            </button>
          </div>
        ),
      },
    ];
    if (finePaymentStatus.status === FinePaymentStatus.Paid) {
      const insertIndex = columnDef.findIndex((column) => column.accessorKey === 'amountPaid') + 1;
      columnDef.splice(insertIndex, 0, {
        header: 'Paid Date',
        accessorFn: (row) =>
          row.paidDate === '0001-01-01T00:00:00'
            ? ''
            : moment(row.paidDate).format('DD-MM-YYYY HH:mm'),
      });
    }
    return columnDef;
  }, [finePaymentStatus.status]);

  const table = useReactTable({
    data: finesQuery?.data?.items ?? [],
    columns,
    state: {
      rowSelection: selectedFines,
      pagination,
    },
    getRowId: (row) => row.id,
    enableRowSelection: (row) => row.original.isPayable,
    onRowSelectionChange: setSelectedFines,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    pageCount: finesQuery.data?.totalPages ?? -1,
  });

  useEffect(() => {
    table.resetRowSelection();
    offenderIdentifiers.setSelected([]);
  }, [profileId]);

  return {
    finesQuery,
    table,
    filter: {
      offenderIdentifiers,
      finePaymentStatus,
      vehicleRegistrations,
      noticeNumberSearch,
      onlyNags: {
        setOnlyNags,
        onlyNags,
      },
    },
  };
};

export default useProfileFinesTable;
