import React, { useMemo, useRef, useState } from 'react';
import { Chip, CircularProgress } from '@material-ui/core';
import { RouteComponentProps, useLocation } from 'react-router';
import _ from 'lodash';
import { useMutation, useQuery } from 'react-query';
import ReactToPrint from 'react-to-print';
import replaceall from 'replaceall';

import { useSelector } from 'react-redux';
import finesService from '../../../services/api/fines/fines.api.service';
import { FineDetailDto, UnpaidOffender } from '../../../types';
import { Button, UnpaidFineSummary, UnpaidTableRow } from '../../../components';
import { paymentsService, referralsService, referralCampaignsService } from '../../../services';
import { useResponsive } from '../../../hooks';
import { businessProfileSelector } from '../../../reducers/business-profile-reducer/business-profile-reducer';

const BusinessBasketScreen: React.FC<
  RouteComponentProps<Record<string, string>, any, FineDetailDto[]>
> = () => {
  const [groupedFines, setGroupedFines] = useState<UnpaidOffender[]>([]);
  const location = useLocation();
  const basketFines = location.state as FineDetailDto[];
  const basketFineIds = useMemo(() => basketFines.map((s) => s.id), [basketFines]);
  const responsive = useResponsive();
  const [pdfString, setPdfString] = useState('');
  const { selectedBusinessEntityOption } = useSelector(businessProfileSelector);

  const userReferralsData =
    useQuery('userReferrals', referralsService.getAllReferrals)?.data ?? null;
  const referralCampaignDiscountData =
    useQuery('referralCampaignDiscount', referralCampaignsService.getActiveReferralCampaignDiscount)
      ?.data ?? 0;
  const userAvailableReferralsDiscount =
    userReferralsData?.filter(
      (referral) =>
        referralsService.referralStatusMapArray.find((m) => m.value === 'Accepted').enum ===
        referral.status.toString(),
    ).length * referralCampaignDiscountData;

  useQuery(
    ['getGroupedFines', basketFines],
    () => finesService.fetchGroupedBusinessFines(selectedBusinessEntityOption.value, basketFineIds),
    {
      onSuccess: (apiResult) => {
        setGroupedFines(apiResult);
      },
    },
  );

  const getQuote = useMutation((fineIds: string[]) =>
    paymentsService.getBusinessEftPaymentQuote(fineIds),
  );
  const getGatewayAddress = useMutation((fineIds: string[]) =>
    paymentsService.getBusinessGatewayPayment(fineIds),
  );

  const componentRef = useRef();

  const ComponentToPrint = React.forwardRef(() => {
    const getPageMargins = () => {
      return `@page { margin: ${5}mm ${5}mm ${5}mm ${5}mm !important; }`;
    };

    let newPdfString = replaceall(
      `src=C:\\home\\site\\wwwroot../../../Common/FinesSA.RazorTemplates/Images/logo_finessa.png`,
      `src="assets/images/logo_finessa.png"`,
      pdfString,
    );
    newPdfString = replaceall(
      `src=C:\\home\\site\\wwwroot../../../Common/FinesSA.RazorTemplates/Images/blue_info_strip.png`,
      `src="assets/images/blue_info_strip.png" style=" flex: 1; display: flex; width: 70%; height: 80%; text-align: center;position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%);"`,
      newPdfString,
    );
    newPdfString = replaceall(
      `text-align: center;`,
      `text-align: center;
      position: relative;
      height: 90;
       `,
      newPdfString,
    );
    newPdfString = replaceall(
      `justify-content: flex-end;`,
      `justify-content: flex-end;
      width: 60%;
      right: 0;
      position: relative;
      bottom: 0;
      padding-bottom: 20;
      padding-left: 20;
       `,
      newPdfString,
    );
    newPdfString = replaceall(
      `width: 187px;`,
      `width: 100%;
      height: 100%;
       `,
      newPdfString,
    );
    newPdfString = replaceall(
      `  width: 60%;`,
      `width: 187px;
      height: 90px;
      right: 0;
      top: 0;
      position: absolute;`,
      newPdfString,
    );
    newPdfString = replaceall(
      `margin-bottom: 20px;`,
      `margin-bottom: 20px;
      margin-top: 20px;
      align-text: center;
      padding-top: 10px;
      padding-left: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;`,
      newPdfString,
    );
    newPdfString = replaceall(
      `line-height: 1.6;`,
      `line-height: 1.6;
      margin-bottom: 20px;
      margin-top: 20px;`,
      newPdfString,
    );

    return (
      <>
        <style type="text/css" media="print">
          {'\
        @page { size: portrait; }\
      '}
        </style>
        <style>{getPageMargins()}</style>
        <img src="assets/images/fines-sa-logo-white.png" alt="finesSA logo" className="nav-logo" />
        <div
          className="p-5"
          ref={componentRef}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: newPdfString }}
        />
      </>
    );
  });

  const generateContent = async () => {
    const getPdfString = await getQuote.mutateAsync(basketFineIds);
    setPdfString(getPdfString);
  };

  const today = new Date();

  const _printNow = () => (
    <div>
      <ReactToPrint
        documentTitle={`FinesSA-Quote-${today.getFullYear()}-${
          today.getMonth() + 1
        }-${today.getDate()}.pdf`}
        trigger={() => (
          <Chip
            label={
              getQuote.isLoading ? <CircularProgress size={20} color="inherit" /> : 'Print Invoice'
            }
            className={`bg-secondary hover:bg-secondary-dark text-white my-1 rounded w-28 ${
              getQuote.isLoading ? 'w-28' : null
            }`}
          />
        )}
        onBeforeGetContent={() => generateContent()}
        content={() => componentRef.current}
      />
    </div>
  );

  const _payWithGateway = async () => {
    const redirectUrl = await getGatewayAddress.mutateAsync(basketFineIds);
    window.open(redirectUrl, '_self');
  };

  const _renderHeaders = () => {
    if (!responsive.isMobile) {
      return (
        <div className="w-full h-14">
          <UnpaidTableRow
            className="h-11 md:flex hidden"
            checked={false}
            showCheckBoxes={false}
            columns={[
              <p className="font-semibold text-lg">Fine Information</p>,
              <p className="font-semibold text-lg">Fine Amount</p>,
              <p className="font-semibold text-lg">Discount</p>,
              <p className="font-semibold text-lg">Other Fees</p>,
              <p className="font-semibold text-lg">Demerit Points</p>,
              <p className="font-semibold text-lg">Amount Paid</p>,
              <p className="font-semibold text-lg">Amount Outstanding</p>,
              <p className="font-semibold text-lg">Icon</p>,
            ]}
          />
        </div>
      );
    }
    return null;
  };

  const _renderSelectedFines = () => {
    return (
      <div className="w-full h-full">
        {_.map(groupedFines, (offender) => {
          const myFines = _.map(offender.fines, (localFine) => {
            return <UnpaidFineSummary showCheckBox={false} key={localFine.id} fine={localFine} />;
          });
          return (
            <div key={`offender-${offender.offenderId}`}>
              <h2 className="text-white font-medium my-5 text-base">{`${offender.offenderName} (${offender.offenderId})`}</h2>
              {myFines}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="w-full h-full px-5" ref={componentRef}>
      <div className="flex justify-end space-x-4">
        <div className="flex items-end">
          <span className="bg-total-green mb-4 p-2 shadow-lg rounded-sm float-right text-xs text-white font-bold">
            R{userAvailableReferralsDiscount} referral credit available for payment with &apos;Pay
            Now&apos;.
          </span>
        </div>
        <div className="flex">
          {groupedFines.length > 0 && (
            <div className="flex flex-col mb-3 items-end">
              {_printNow()}
              <Button
                onClick={_payWithGateway}
                size="small"
                className="bg-tertiary hover:bg-tertiary-dark text-white my-1 w-28"
                isLoading={getGatewayAddress.isLoading}
              >
                Pay Now
              </Button>
            </div>
          )}
        </div>
      </div>
      {_renderHeaders()}
      {_renderSelectedFines()}
      <div className="hidden">
        <ComponentToPrint ref={componentRef} />
      </div>
    </div>
  );
};

export default BusinessBasketScreen;
