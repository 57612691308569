import React, { useState } from 'react';
import { Avatar, Menu, MenuItem, Typography } from '@material-ui/core';
import { NavLink, useHistory } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import Modal from 'react-modal';

import { Toaster } from 'react-hot-toast';
import { Button } from '../..';
import { useUserInfo } from '../../../react-queries';
import { decodedToken, userAuthService } from '../../../services';
import { setUserRole } from '../../../reducers/userRole-reducer/userRole.actions';
import { useAppDispatch } from '../../../reducers/store';
import { FineSyncConfigurationOrganism } from '../../organisms/fine-sync-configuration-organism/fine-sync-configuration-organism';

const customStyles = () => {
  return {
    overlay: {
      zIndex: 10,
      background:
        'linear-gradient(290deg, #061F40D9 0%, #031020D9 10%, #101925D9 30%, #0C0C0CD9 100%) 0% 0% no-repeat padding-box',
      boxShadow: '0px 20px 50px #3E3E3E4D',
      filter: 'blur(0px)',
    },
  };
};

export const AdminLayout: React.FC = ({ children }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalType, setModalType] = useState('');

  const removeChatBox = () => {
    const tawkApi = (window as any).Tawk_API;
    if (tawkApi) tawkApi.hideWidget();
  };
  setTimeout(() => {
    removeChatBox();
  }, 4000);

  const userRole = decodedToken.getRole();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const _navigateToAdmin = () => {
    history.push('/admin');
  };
  const queryClient = useQueryClient();

  const _signOut = async () => {
    await userAuthService.logout();
    queryClient.clear();
    await dispatch(setUserRole(''));
    history.push('/login');
  };

  const userInfo = useUserInfo();
  const [anchorElement, setAnchor] = useState<null | HTMLElement>(null);
  const _openUser = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchor(event.currentTarget);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const openModal = (type?: string) => {
    setModalType(type);
    setModalIsOpen(true);
    setAnchor(null);
  };

  return (
    <>
      <Toaster />
      <div className="bg-cover h-screen absolute bottom-0 top-0 left-0 right-0 -z-10" />
      <div className="flex flex-1 flex-col h-screen">
        <nav className="px-4 inline-flex justify-between bg-secondary h-13 border-solid border-b-4 border-gold-brand-1">
          <ul className="h-full inline-flex items-center space-x-16">
            <li className="">
              <Button
                onClick={_navigateToAdmin}
                className="bg-transparent shadow-none hover:bg-transparent hover:shadow-none"
              >
                <img
                  src="/assets/images/fines-sa-logo-white.png"
                  alt="finesSA logo"
                  className="nav-logo w-19"
                />
              </Button>
            </li>
            <li>
              <NavLink
                activeStyle={{ textDecoration: 'underline' }}
                type="text"
                className="text-white text-xs font-medium flex items-center h-10"
                to="/admin/fines"
              >
                Fines
              </NavLink>
            </li>
            <li>
              <NavLink
                activeStyle={{ textDecoration: 'underline' }}
                type="text"
                className="text-white text-xs font-medium flex items-center h-10"
                to="/admin/users"
              >
                Accounts
              </NavLink>
            </li>
            <li>
              <NavLink
                activeStyle={{ textDecoration: 'underline' }}
                type="text"
                className="text-white text-xs font-medium flex items-center h-10"
                to="/admin/invoice"
              >
                Invoice
              </NavLink>
            </li>
            {userRole === 'SuperAdministrator' || userRole === 'CallCenterManager' ? (
              <li>
                <NavLink
                  activeStyle={{ textDecoration: 'underline' }}
                  type="text"
                  className="text-white text-xs font-medium flex items-center h-10"
                  to="/admin/consultant"
                >
                  Consultant
                </NavLink>
              </li>
            ) : null}
            {userRole === 'SuperAdministrator' || userRole === 'CallCenterManager' ? (
              <li>
                <NavLink
                  activeStyle={{ textDecoration: 'underline' }}
                  type="text"
                  className="text-white text-xs font-medium flex items-center h-10"
                  to="/admin/reports"
                >
                  Reporting
                </NavLink>
              </li>
            ) : null}
            {userRole === 'SuperAdministrator' ||
            userRole === 'CallCenterManager' ||
            userRole === 'CallCenterAgent' ? (
              <li>
                <NavLink
                  activeStyle={{ textDecoration: 'underline' }}
                  type="text"
                  className="text-white text-xs font-medium flex items-center h-10"
                  to="/admin/call-centre"
                >
                  Call Centre
                </NavLink>
              </li>
            ) : null}
            {userRole === 'SuperAdministrator' ? (
              <li>
                <NavLink
                  activeStyle={{ textDecoration: 'underline' }}
                  type="text"
                  className="text-white text-xs font-medium flex items-center h-10"
                  to="/admin/payment-partners"
                >
                  Payment Partners
                </NavLink>
              </li>
            ) : null}
          </ul>
          <ul className="h-full inline-flex ">
            <li className="h-full flex py-4">
              {!userInfo.isLoading && (
                <>
                  <Button
                    className="flex flex-row items-center border-none "
                    onClick={_openUser}
                    type="outlined"
                  >
                    <li className="h-full flex py-4">
                      <Avatar className="mr-3 w-7.5 h-7.5 text-sm my-auto">{`${userInfo?.data?.firstName[0]}${userInfo?.data?.lastName[0]}`}</Avatar>
                      <div className="flex flex-col items-start">
                        <p className="text-white text-xs">{`${userInfo?.data?.firstName} ${userInfo?.data?.lastName}`}</p>
                        <p className="text-white font-thin text-xs">{userInfo?.data?.email}</p>
                      </div>
                    </li>
                  </Button>
                  <Menu
                    anchorEl={anchorElement}
                    open={!!anchorElement}
                    onClose={() => setAnchor(null)}
                    PopoverClasses={{ paper: 'bg-secondary' }}
                  >
                    <div className="flex flex-row px-3">
                      <Avatar className="mr-3 w-7.5 h-7.5 text-sm my-auto">{`${userInfo?.data?.firstName[0]}${userInfo?.data?.lastName[0]}`}</Avatar>
                      <div className="flex flex-col items-start">
                        <p className="text-white text-xs">{`${userInfo?.data?.firstName} ${userInfo?.data?.lastName}`}</p>
                        <p className="text-white font-thin text-xs">{userInfo?.data?.email}</p>
                      </div>
                    </div>
                    <MenuItem onClick={() => openModal('fineSyncConfiguration')}>
                      <img src="/assets/icons/pencil-white-icon.png" alt="user" />
                      <Typography className="text-white font-thin px-3">
                        Fine Sync Configuration
                      </Typography>
                    </MenuItem>
                    <MenuItem onClick={_signOut}>
                      <img src="/assets/icons/log-out-white-icon.png" alt="logout" />
                      <Typography className="text-white font-thin px-3">Logout</Typography>
                    </MenuItem>
                  </Menu>
                </>
              )}
            </li>
          </ul>
        </nav>
        <div className="h-full flex flex-col">{children}</div>
      </div>

      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles()}>
        {modalType === 'fineSyncConfiguration' && (
          <FineSyncConfigurationOrganism closeModal={closeModal} />
        )}
      </Modal>
    </>
  );
};
