import React, { useRef, useState } from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import Modal from 'react-modal';
import { useQueryParam } from 'use-query-params';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { Tab } from '@headlessui/react';
import { adminUserService, adminProfilesService } from '../../../services';
import {
  ProfileNote,
  AccountInfo,
  BusinessEntities,
  FamilyMembers,
  ProfileFines,
  BusinessEntityLinks,
} from '../../../components';

import { useAutosizeTextArea, useDownloadFile } from '../../../hooks/global';
import { Guid, ProfileType } from '../../../types';
import { useBusiness, useIndividual, useAccountInfoQuery } from '../../../hooks/profile';
import { classNames } from '../../../helpers/class-names';
import { getAccountProfiles } from '../../../services/api/admin-profiles/admin-profiles.service';
import userService from '../../../services/api/user/user.service';
import welcomeEmailService from '../../../services/api/welcome-email/welcome-email.service';

Modal.setAppElement('#root');

const AccountsScreen: React.FC = () => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const history = useHistory();
  const queryClient = useQueryClient();

  const [individualNote, setIndividualNote] = useState<string>('');
  const [fleetNote, setFleetNote] = useState<string>('');

  const [userId] = useQueryParam<Guid>('Id');

  const [activeProfile, setActiveProfile] = useState<ProfileType>(ProfileType.Individual);

  const business = useBusiness(userId);
  const individual = useIndividual(userId);
  const accountInfoQuery = useAccountInfoQuery(userId, activeProfile);

  useAutosizeTextArea(textAreaRef.current, individualNote);

  const accountProfilesQuery = useQuery(
    ['AdminAccountProfileData', userId],
    () => getAccountProfiles(userId),
    {
      enabled: !!userId,
      onSuccess: (data) => {
        if (individualNote === '') {
          setIndividualNote(data?.individual?.note);
        }
        if (fleetNote === '') {
          setFleetNote(data?.business?.note);
        }
      },
    },
  );

  const useSendInvitationEmailMutation = useMutation(
    () => welcomeEmailService.send(accountInfoQuery.data.email),
    {
      onSuccess: () => {
        toast.success('Successfully sent invitation');
      },
    },
  );

  const useSetIndividualNoteMutation = useMutation(
    () =>
      adminProfilesService.setProfileNote({
        note: individualNote,
        profileId: individual.id,
        isHuman: true,
      }),
    {
      onError: () => {
        toast.error('An error occurred updating profile note');
      },
    },
  );

  const useSetFleetEntityNoteMutation = useMutation(
    () =>
      adminProfilesService.setProfileNote({
        note: fleetNote,
        profileId: business.fleetId,
        isHuman: false,
      }),
    {
      onError: () => {
        toast.error('An error occurred updating profile note');
      },
    },
  );

  const useDeletePortalUserMutation = useMutation(
    'deletePortalUserMutation',
    () => userService.deletePortalUserAccount(userId),
    {
      onSuccess: () => {
        toast.success('Successfully deleted user');
        history.goBack();
      },
      onError: () => {
        toast.error('An error occurred deleting Portal User');
      },
    },
  );

  const useArchiveProfileMutation = useMutation(
    'archiveProfile',
    () => adminProfilesService.update(userId, { ...accountInfoQuery.data, isArchived: true }),
    {
      onSuccess: () => {
        toast.success('Successfully archived user');
        history.goBack();
      },
      onError: () => {
        toast.error('An error occurred archived Portal User');
      },
    },
  );

  const useUnarchiveProfileMutation = useMutation(
    'unarchiveProfile',
    () => adminProfilesService.update(userId, { ...accountInfoQuery.data, isArchived: false }),
    {
      onSuccess: () => {
        toast.success('Successfully unarchived user');
        queryClient.invalidateQueries(['AdminAccountProfileData', userId]);
      },
      onError: () => {
        toast.error('An error occurred unarchived Portal User');
      },
    },
  );

  const { mutate: mutateIsWhatsAppVerified } = useMutation(
    'updateIsWhatsAppVerified',
    ([updateUserId, isWhatsAppVerified]: [Guid, boolean]) =>
      userService.updateIsWhatsAppVerified(updateUserId, isWhatsAppVerified),
    {
      onSuccess: () => {
        toast.success('WhatsApp verification has successfully updated');
        queryClient.invalidateQueries(['AdminAccountProfileData', userId]);
      },
      onError: () => {
        toast.error('WhatsApp verification failed to update');
      },
    },
  );

  const onClickToggleIsWhatsAppVerified = () => {
    mutateIsWhatsAppVerified([accountInfoQuery.data.id, !accountInfoQuery.data.isWhatsAppVerified]);
  };

  const copyLinkToClipboard = (link: string) => {
    navigator.clipboard.writeText(link).then(() => toast.success(`Link copied to clipboard.`));
  };

  const resetPassword = useMutation(() => adminUserService.generateResetPasswordLink(userId), {
    onSuccess: (apiResult) => {
      copyLinkToClipboard(apiResult.link);
    },
    onError: () => {
      toast.error('An error occurred while generating the reset password link. Please try again.');
    },
  });
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);

  const [showMenu, setShowMenu] = useState(false);

  const { download: downloadPaidFinesCsv } = useDownloadFile({
    apiDefinition: () =>
      adminProfilesService.exportProfileFinesToCsv(
        activeProfile === ProfileType.Individual ? userId : business.activeBusinessEntityId,
        activeProfile === ProfileType.Individual,
        true,
      ),
    onSuccess: () => setShowMenu(false),
    onError: () => {
      toast.error('Could not export fines');
    },
    fileName: 'paid-fines-export.csv',
  });

  const { download: downloadUnPaidFinesCsv } = useDownloadFile({
    apiDefinition: () =>
      adminProfilesService.exportProfileFinesToCsv(
        activeProfile === ProfileType.Individual ? userId : business.activeBusinessEntityId,
        activeProfile === ProfileType.Individual,
        false,
      ),
    onSuccess: () => setShowMenu(false),
    onError: () => {
      toast.error('Could not export fines');
    },
    fileName: 'unpaid-fines-export.csv',
  });
  const menuButton = () => {
    const handleMenuClose = () => {
      setAnchorElement(null);
      setShowMenu(false);
    };

    const _copyResetPasswordLinkToClipboard = () => {
      if (!resetPassword.isSuccess) {
        resetPassword.mutate();
        setShowMenu(false);
      } else if (resetPassword.data) {
        copyLinkToClipboard(resetPassword.data.link);
        setShowMenu(false);
      } else {
        resetPassword.mutate();
        setShowMenu(false);
      }
    };

    const _deleteUserAccount = () => {
      // eslint-disable-next-line no-alert
      if (window.confirm('Are you sure you wish to delete this account?')) {
        useDeletePortalUserMutation.mutate();
      }
    };

    const _archiveUserAccount = () => {
      // eslint-disable-next-line no-alert
      if (window.confirm('Are you sure you wish to archive this account?')) {
        useArchiveProfileMutation.mutate();
      }
    };

    const _unarchiveUserAccount = () => {
      // eslint-disable-next-line no-alert
      if (window.confirm('Are you sure you wish to unarchive this account?')) {
        useUnarchiveProfileMutation.mutate();
      }
    };

    const handleMenuButtonClick = (event: React.MouseEvent<HTMLElement>) => {
      const anchorLocation = event.currentTarget;
      setShowMenu(true);
      setAnchorElement(anchorLocation);
    };

    return (
      <div className="absolute right-0">
        <div>
          <IconButton
            className="float-right"
            id="options-menu-button"
            aria-controls={showMenu ? 'options-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={showMenu ? 'true' : undefined}
            onClick={handleMenuButtonClick}
          >
            <MoreVert />
          </IconButton>
          <div>
            <Menu
              id="options-menu"
              anchorEl={anchorElement}
              open={showMenu}
              keepMounted
              onClose={handleMenuClose}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <MenuItem onClick={downloadUnPaidFinesCsv}>Export Unpaid Fines</MenuItem>
              <MenuItem onClick={downloadPaidFinesCsv}>Export Paid Fines</MenuItem>
              <MenuItem onClick={_copyResetPasswordLinkToClipboard}>Reset Password Link</MenuItem>
              <MenuItem onClick={() => useSendInvitationEmailMutation.mutate()}>
                Send Invitation
              </MenuItem>
              {accountInfoQuery?.data?.isArchived ? (
                <MenuItem className="text-green-500" onClick={_unarchiveUserAccount}>
                  Unarchive Account
                </MenuItem>
              ) : (
                <MenuItem className="text-tertiary" onClick={_archiveUserAccount}>
                  Archive Account
                </MenuItem>
              )}
              {accountInfoQuery?.data?.isWhatsAppVerified ? (
                <MenuItem className="text-tertiary" onClick={onClickToggleIsWhatsAppVerified}>
                  Toggle WhatsApp as Unverified
                </MenuItem>
              ) : (
                <MenuItem className="text-green-500" onClick={onClickToggleIsWhatsAppVerified}>
                  Toggle WhatsApp as Verified
                </MenuItem>
              )}
              <MenuItem className="text-tertiary" onClick={_deleteUserAccount}>
                Delete Account
              </MenuItem>
            </Menu>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col flex-grow mx-4">
      {menuButton()}
      <Tab.Group selectedIndex={activeProfile} onChange={setActiveProfile}>
        <Tab.List className="flex items-center">
          {['Individual', 'Business'].map((value) => (
            <Tab
              key={value}
              className={({ selected }) =>
                classNames(
                  'rounded-md p-2.5 text-base font-medium leading-5 my-2 mx-1',
                  'ring-white ring-opacity-60 ring-offset-2 focus:outline-none',
                  selected ? 'bg-secondary text-white shadow' : 'text-black-100 hover:bg-gray-200',
                )
              }
            >
              {value}
            </Tab>
          ))}
          {accountProfilesQuery.data?.doNotContact ? (
            <div className="bg-red-500 rounded-md text-white p-1 text-xs">
              <span>Do Not Contact</span>
            </div>
          ) : null}
          {accountProfilesQuery.data?.individual.syntellExportSyncActive ? (
            <div className="bg-green-500 rounded-md text-white p-1 text-xs mr-1">
              <span>Syntell Export Active</span>
            </div>
          ) : null}
          {accountProfilesQuery.data?.isArchived ? (
            <div className="bg-tertiary rounded-md text-white p-1 text-xs">
              <span>Archived</span>
            </div>
          ) : null}
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel>
            <div className="flex flex-row gap-x-4 mb-3">
              <AccountInfo
                profileType={activeProfile}
                userId={userId}
                accountInfoQuery={accountInfoQuery}
              />
              <FamilyMembers
                hasIndividualProfile={individual.hasIndividualProfile}
                individualId={individual.id}
                familyMembers={individual.familyMembers}
              />
              <ProfileNote
                isLoading={accountProfilesQuery.isLoading}
                ref={textAreaRef}
                value={individualNote}
                onChange={(e) => setIndividualNote(e.target.value)}
                onBlur={() => useSetIndividualNoteMutation.mutate()}
              />
            </div>
          </Tab.Panel>
          <Tab.Panel>
            <div className="flex flex-row gap-x-4 mb-3">
              <AccountInfo
                profileType={activeProfile}
                userId={userId}
                accountInfoQuery={accountInfoQuery}
              />
              <BusinessEntities
                activeEntityId={business.activeBusinessEntityId}
                setActiveBusinessEntityId={business.setActiveBusinessEntityId}
                businessEntities={business.businessEntities}
                fleetId={business.fleetId}
                businessOwnerId={business.ownerId}
              />
              <BusinessEntityLinks
                businessEntityLinks={business.businessEntityLinks}
                entityCount={business.businessEntities.length}
                activeBusinessEntityId={business.activeBusinessEntityId}
              />
              <ProfileNote
                isLoading={accountProfilesQuery.isLoading}
                ref={textAreaRef}
                value={fleetNote}
                onChange={(e) => setFleetNote(e.target.value)}
                onBlur={() => useSetFleetEntityNoteMutation.mutate()}
              />
            </div>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
      <ProfileFines
        userId={userId}
        profileType={activeProfile}
        businessEntityId={business.activeBusinessEntityId}
      />
    </div>
  );
};

export default AccountsScreen;
