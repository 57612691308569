import _ from 'lodash';
import * as Yup from 'yup';
import {
  parsePhoneNumberWithError,
  ParseError,
  validatePhoneNumberLength,
  PhoneNumber,
} from 'libphonenumber-js/max';
import { StringHelper } from '../helpers/string-helper';
import { ValidationHelper } from '../helpers';
import { OffenderIdentifierType } from '../enums';

function matchPasswords() {
  return this.parent.password === this.parent.confirmPassword;
}

function entityArrayLengthOfOne() {
  return this.parent.businessEntities.length >= 1;
}

function linkArrayLengthOfOne() {
  return this.parent.links.length >= 1;
}

function matchEmail() {
  return this.parent.email === this.parent.confirmEmail;
}

function matchIdNumber() {
  return this.parent.idNumber === this.parent.confirmIdNumber;
}

const errorMessage = (err) => {
  switch (err) {
    case 'NOT_A_NUMBER':
      return 'This number contains invalid characters';
    case 'INVALID_COUNTRY':
      return 'The country code is invalid';
    case 'TOO_SHORT':
      return 'This number is too short';
    case 'INVALID_LENGTH':
      return 'This number has an invalid length';
    case 'TOO_LONG':
      return 'This number is too long';
    default:
      return 'There is an error with the number entered';
  }
};

const baseOffenderValidationSchema = {
  offenderIdentifierIndividualType: Yup.number().oneOf(
    [OffenderIdentifierType.SaIdNumber, OffenderIdentifierType.Trn],
    'link must be one of ID or TRN',
  ),
  offenderIdentifierFleetType: Yup.number().oneOf(
    [OffenderIdentifierType.ProxyId, OffenderIdentifierType.Brn],
    'link must be one of Proxy ID or BRN',
  ),
  offenderIdentifierIndividualValue: Yup.string().when('identifierType', (identifierType) =>
    identifierType?.toString() === OffenderIdentifierType.SaIdNumber.toString()
      ? Yup.string()
          .length(13, 'ID Number must be 13 digits long')
          .test(
            'containsInvalidCharacter',
            'Identifier can only contain letter or number',
            StringHelper.isLettersOrDigits,
          )
          .test('isInvalidIdNumber', 'Identifier is not valid', ValidationHelper.IsLuhnValid)
          .required('ID Number is Required')
      : Yup.string()
          .test(
            'isInvalidTrn',
            'TRN needs to be between 10-13 characters',
            (value: string | undefined) => !(value.length < 10 || value.length > 13),
          )
          .test(
            'containsInvalidCharacter',
            'Identifier can only contain letter or number',
            StringHelper.isLettersOrDigits,
          )
          .required('ID/TRN Number is Required'),
  ),
  offenderIdentifierFleetValue: Yup.string().when('identifierType', (identifierType) =>
    identifierType?.toString() === OffenderIdentifierType.ProxyId.toString()
      ? Yup.string()
          .length(13, 'Proxy ID must be 13 digits long')
          .test(
            'containsInvalidCharacter',
            'Identifier can only contain letter or number',
            StringHelper.isLettersOrDigits,
          )
          .test('isInvalidIdNumber', 'Identifier is not valid', ValidationHelper.IsLuhnValid)
          .required('Proxy ID is Required')
      : Yup.string()
          .length(13, 'BRN must be 13 digits long')
          .test(
            'containsInvalidCharacter',
            'Identifier can only contain letter or number',
            StringHelper.isLettersOrDigits,
          )
          .required('BRN Number is Required'),
  ),
};

const baseLinksValidationSchema = {
  links: Yup.array().of(
    Yup.lazy((value) => {
      if (!_.get(value, 'identifierType', '') && !_.get(value, 'value', '')) {
        return Yup.mixed().notRequired();
      }
      return Yup.object().shape({
        identifierType: Yup.number().oneOf(
          [OffenderIdentifierType.ProxyId, OffenderIdentifierType.Brn],
          'link must be one of proxy ID or BRN',
        ),
        value: baseOffenderValidationSchema.offenderIdentifierFleetValue,
      });
    }) as any,
  ),
};

const validationSchema = {
  email: Yup.string().email('Email must be a valid email').required('Email is required'),
  confirmEmail: Yup.mixed()
    .test('match', 'Email does not match', matchEmail)
    .required('Re-enter your email'),
  password: Yup.string()
    .min(6, 'Password must contain at least 6 characters')
    .required('Enter your password'),
  confirmPassword: Yup.mixed()
    .test('match', 'Password does not match', matchPasswords)
    .required('Re-enter your password'),
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  code: Yup.string()
    .matches(/^[^a-zA-Z!@#$%^&*(),.?":{}|<>+-]/, 'Must only contain numbers')
    .required('International code is required'),
  cellphone: Yup.string().test({
    // fieldName: 'cellphone',
    test: function internationalPhoneTest() {
      const intCode = this.parent.code;
      const cellNum = this.parent.cellphone;
      const internationalNumber = `+${intCode} ${cellNum}`;
      let phoneError;
      let phoneNumber;
      try {
        phoneNumber = parsePhoneNumberWithError(internationalNumber);
        // isValid() is returning false with the 3 digit intCode. Work around is to specifically test those cases
        phoneError =
          intCode.length === 3 && cellNum.length === 9
            ? 'Success'
            : phoneNumber.isValid()
            ? 'Success'
            : 'Invalid';
      } catch (error) {
        if (error instanceof ParseError) {
          phoneError = error.message;
        } else {
          phoneError = error;
        }
      }

      if (phoneError === 'Success') {
        return true;
      }
      if (phoneError === 'Invalid') {
        return this.createError({
          message: `${errorMessage(validatePhoneNumberLength(internationalNumber))}`,
          path: 'cellphone',
        });
      }
      return this.createError({
        message: `${errorMessage(phoneError)}`,
        path: 'cellphone',
      });
    },
  }),
  cellphoneNumber: Yup.string(),
  termsChecked: Yup.boolean().oneOf([true], 'Terms must be accepted'),
  idNumber: baseOffenderValidationSchema.offenderIdentifierIndividualValue,
  confirmIdNumber: Yup.string()
    .required('Re-enter your Identifier')
    .test('match', 'ID number does not match', matchIdNumber),
  communicationEmail: Yup.string()
    .email('Email must be a valid email')
    .test(
      'unique-emails',
      'Cannot add main account email as additional communication email',
      function validateCommunicationEmail(value) {
        const { email } = this.parent;
        return value !== email;
      },
    ),
  familyMembers: Yup.array().of(
    Yup.lazy((value) => {
      if (
        !_.get(value, 'firstName', '') &&
        !_.get(value, 'lastName', '') &&
        !_.get(value, 'idNumber', '')
      ) {
        return Yup.mixed().notRequired();
      }
      return Yup.object().shape({
        firstName: Yup.string().required('Friend or family member first name is required.'),
        lastName: Yup.string().required('Friend or family member last name is required.'),
        idNumber: baseOffenderValidationSchema.offenderIdentifierIndividualValue,
      });
    }) as any,
  ),
  businessEntities: Yup.array()
    .of(
      Yup.lazy((businessEntity) => {
        if (
          _.isEmpty(businessEntity.entityName) &&
          businessEntity.links.every((link) => _.isEmpty(link.value))
        ) {
          return Yup.mixed().notRequired();
        }
        return Yup.object({
          entityName: Yup.string().required('Name of business entity is required'),
          links: baseLinksValidationSchema.links.test(
            'minimum',
            'Must Specify at least one ProxyID or BRN',
            linkArrayLengthOfOne,
          ),
        });
      }) as any,
    )
    .test('minimum', 'Must Specify at least one Business Entity', entityArrayLengthOfOne),
  offenderIdentifier: Yup.object().shape({
    value: baseOffenderValidationSchema.offenderIdentifierIndividualValue,
    identifierType: baseOffenderValidationSchema.offenderIdentifierIndividualType,
  }),
  offenderIdentifierFleet: Yup.object().shape({
    value: baseOffenderValidationSchema.offenderIdentifierFleetValue,
    identifierType: baseOffenderValidationSchema.offenderIdentifierFleetType,
  }),
  entityName: Yup.string().required('Entity Name is required'),
  address: Yup.string().notRequired(),
  vatNumber: Yup.string().notRequired(),
  companyRegistration: Yup.string().notRequired(),
};

export const loginValidation = Yup.object({
  username: validationSchema.email,
  password: validationSchema.password,
});

export const forgotPasswordValidation = Yup.object({
  email: validationSchema.email,
});

export const resetPasswordValidation = Yup.object({
  password: validationSchema.password,
  confirmPassword: validationSchema.confirmPassword,
});

// A profile consists of possibly a individual and/or a fleet
// If it's only a fleet then the user doesn't have an individual
// profile and we cannot validate it then
export const editFleetOnlyAccountValidation = Yup.object({
  firstName: validationSchema.firstName,
  lastName: validationSchema.lastName,
  code: validationSchema.code,
  cellphoneNumber: validationSchema.cellphoneNumber,
  cellphone: validationSchema.cellphone,
  email: validationSchema.email,
  confirmEmail: validationSchema.confirmEmail,
  communicationEmail: validationSchema.communicationEmail,
});

export const editAccountValidation = Yup.object({
  firstName: validationSchema.firstName,
  lastName: validationSchema.lastName,
  code: validationSchema.code,
  cellphoneNumber: validationSchema.cellphoneNumber,
  cellphone: validationSchema.cellphone,
  email: validationSchema.email,
  confirmEmail: validationSchema.confirmEmail,
  idNumber: validationSchema.idNumber,
  communicationEmail: validationSchema.communicationEmail,
});

export const onboardIndividualValidation = Yup.object({
  idNumber: validationSchema.idNumber,
  confirmIdNumber: validationSchema.confirmIdNumber,
  familyMembers: validationSchema.familyMembers,
});

export const editFamilyValidation = Yup.object({
  idNumber: validationSchema.idNumber,
  confirmIdNumber: validationSchema.confirmIdNumber,
  familyMembers: validationSchema.familyMembers,
});

export const onboardBusinessValidation = Yup.object({
  businessEntities: validationSchema.businessEntities,
});

export const addReferralValidation = Yup.object({
  referrals: Yup.array()
    .of(
      Yup.object({
        firstName: validationSchema.firstName,
        emailAddress: validationSchema.email,
      }),
    )
    .test('Unique', 'Referral emails must be unique', (values) => {
      return new Set(values.map((value) => value.emailAddress)).size === values.length;
    }),
});
const accountValidation = Yup.object({
  firstName: validationSchema.firstName,
  lastName: validationSchema.lastName,
  email: validationSchema.email,
  confirmEmail: validationSchema.confirmEmail,
  code: validationSchema.code,
  cellphoneNumber: validationSchema.cellphoneNumber,
  cellphone: validationSchema.cellphone,
  password: validationSchema.password,
  confirmPassword: validationSchema.confirmPassword,
  termsChecked: validationSchema.termsChecked,
});

export const signUpValidation = Yup.object({
  account: accountValidation,
  individual: onboardIndividualValidation,
  business: onboardBusinessValidation,
});

export const signUpValidationBusiness = Yup.object({
  account: accountValidation,
  business: onboardBusinessValidation,
});

export const signUpValidationIndividual = Yup.object({
  account: accountValidation,
  individual: onboardIndividualValidation,
});

export const adminFamilyMemberValidationSchema = Yup.object().shape({
  firstName: validationSchema.firstName,
  lastName: validationSchema.lastName,
  offenderIdentifier: validationSchema.offenderIdentifier,
});

export const fleetEntityFormValidationSchema = Yup.object().shape({
  entityName: validationSchema.entityName,
  address: validationSchema.address,
  vatNumber: validationSchema.vatNumber,
  companyRegistration: validationSchema.companyRegistration,
  links: baseLinksValidationSchema.links,
});

export const editFleetEntityLinkValidationSchema = validationSchema.offenderIdentifierFleet;

export const addFleetEntityValidationSchema = Yup.object().shape({
  entityName: validationSchema.entityName,
  address: Yup.string().required('Address is Required'),
});

export const profileEditValidationSchema = Yup.object().shape({
  identifier: baseOffenderValidationSchema.offenderIdentifierIndividualValue,
  identifierType: baseOffenderValidationSchema.offenderIdentifierIndividualType,
  communicationEmail: validationSchema.communicationEmail,
});

export const callOutcomeFormValidationSchema = Yup.object().shape({
  callDate: Yup.string().required(),
  agentFullName: Yup.string().required(),
  callOutcome: Yup.number().required(),
  durationInSeconds: Yup.number().required(),
  doNotContact: Yup.boolean().required(),
  callBack: Yup.boolean().required(),
  callBackDate: Yup.date().when('callBack', (callBack) =>
    callBack?.toString() === 'true'
      ? Yup.date().required('Call-back Date is Required')
      : Yup.date().optional(),
  ),
});

export const editCallCentreProfileFormValidationSchema = Yup.object().shape({
  userId: Yup.string().required(),
  firstName: validationSchema.firstName,
  lastName: validationSchema.lastName,
  email: validationSchema.email,
  partner: Yup.string().required('Partner is required'),
  cellphoneNumber: Yup.string()
    .test((cellphoneNumber) => {
      const intCode = `+${cellphoneNumber.substring(0, 2)}`;
      const cellNum = cellphoneNumber.substring(2, cellphoneNumber.length + 1);
      const internationalNumber = `${intCode} ${cellNum}`;
      let phoneError: string;
      let phoneNumber: PhoneNumber;
      try {
        phoneNumber = parsePhoneNumberWithError(internationalNumber);
        phoneError =
          intCode.length === 3 && cellNum.length === 9
            ? 'Success'
            : phoneNumber.isValid()
            ? 'Success'
            : 'Invalid';
      } catch (error) {
        if (error instanceof ParseError) {
          phoneError = error.message;
        } else {
          phoneError = error;
        }
      }

      if (phoneError === 'Success') {
        return true;
      }
      if (phoneError === 'Invalid') {
        return false;
      }
      return false;
    })
    .length(11)
    .required('Phone Number is required'),
});

export const editCallCenterAgentSchema = Yup.object().shape({
  extension: Yup.string(),
});
