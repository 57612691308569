import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { Card, Checkbox, Chip, CircularProgress, MenuItem, Select } from '@material-ui/core';
import numberFormatter from 'number-formatter';
import { useHistory } from 'react-router';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryClient } from 'react-query';
import { useQueryParam } from 'use-query-params';
import {
  TailwindButton,
  UnpaidTableRow,
  UnpaidFineSummary,
  PaidTableRow,
  PaidFineSummary,
} from '../../../components';
import { FineDetailDto, UnpaidOffender, Guid } from '../../../types';
import { FineValue } from './individual.types';
import {
  useIndividualFilterData,
  useIndividualPaidFines,
  useIndividualUnpaidFines,
} from '../../../react-queries';
import { useResponsive } from '../../../hooks';
import { decodedToken, referralsService } from '../../../services';
import { syncFinesAction } from '../../../reducers/sync-fines-reducer/sync-fines.actions';
import { syncFineSelector } from '../../../reducers/sync-fines-reducer/sync-fines.reducer';

const customStyles = () => {
  return {
    overlay: {
      zIndex: 10,
      background:
        'linear-gradient(290deg, #061F40D9 0%, #031020D9 10%, #101925D9 30%, #0C0C0CD9 100%) 0% 0% no-repeat padding-box',
      boxShadow: '0px 20px 50px #3E3E3E4D',
      filter: 'blur(0px)',
    },
    content: {
      top: '50%',
      left: '50%',
      width: '215px',
      height: '158px',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '30px',
      padding: 0,
      zIndex: 100,
      opacity: 1,
    },
  };
};

Modal.setAppElement('#root');

const IndividualScreen: React.FC = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState('');
  const [, setAnchor] = useState<null | HTMLElement>(null);
  const [isUnpaid, setIsUnpaid] = useState(true);
  const [fines, setFines] = useState<FineValue[]>([]);
  const [filter, setFilter] = useState<string[]>([]);
  const [basketTotal, setBasketTotal] = useState<number>(0);
  const history = useHistory();
  const individualFilterData = useIndividualFilterData();
  const individualUnpaidFines = useIndividualUnpaidFines(filter, isUnpaid && !!filter.length);
  const individualPaidFines = useIndividualPaidFines(filter, !isUnpaid && !!filter.length);
  const responsive = useResponsive();
  const userId = decodedToken.decodedTokenUserId();
  const dispatch = useDispatch();
  const { isLoading, syncSuccessful } = useSelector(syncFineSelector);
  const queryClient = useQueryClient();
  const [action] = useQueryParam<string>('action');
  const [invoiceId] = useQueryParam<Guid>('paymentId');

  if (action && action === 'cancelPayment' && invoiceId) {
    referralsService.cancelReferrals(invoiceId).then(() => {
      history.push('/individual');
    });
  }

  useEffect(() => {
    dispatch(syncFinesAction());
  }, [userId]);

  useEffect(() => {
    if (syncSuccessful) {
      queryClient.invalidateQueries('finesIndividualLinkedData');
      queryClient.invalidateQueries('getIndividualUnpaidFines');
    }
  }, [syncSuccessful]);

  useEffect(() => {
    if (!individualFilterData?.data) return;

    setFilter(individualFilterData.data.map((user) => user.idNumber));
  }, [individualFilterData?.data?.map((userData) => userData.idNumber).toString()]);

  useEffect(() => {
    let price = 0;
    _.forEach(fines, (fine) => {
      if (fine.checked) {
        price += fine.outstandingAmount;
      }
    });

    setBasketTotal(price);
  }, [filter, fines]);

  const openModal = (type?: string) => {
    setModalType(type);
    setIsOpen(true);
    setAnchor(null);
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  useEffect(() => {
    if (isLoading) {
      openModal('fineSyncing');
    }
    if (!isLoading) {
      closeModal();
    }
  });
  const _newFinesAdded = (apiFineData: UnpaidOffender[]) => {
    const newFines: FineValue[] = [];

    _.forEach(apiFineData, (individual) => {
      _.forEach(individual.fines, (fine) => {
        const existingFine = _.find(fines, (oldFine) => oldFine.id === fine.id);

        if (_.isEmpty(existingFine)) {
          if (fine.isPayable) {
            newFines.push({
              checked: true,
              id: fine.id,
              outstandingAmount: fine.total,
            });
          }
        }
      });
    });
    if (!_.isEmpty(newFines)) {
      setFines([...fines, ...newFines]);
    }
  };

  useEffect(() => {
    _newFinesAdded(individualUnpaidFines.data);
  }, [individualUnpaidFines]);

  const _toggleFineSelected =
    (fine: FineDetailDto) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!fine.isPayable) {
        return;
      }
      const localSelectedFines = _.cloneDeep(fines);
      const selectedFine = _.find(localSelectedFines, (fineValue) => fineValue.id === fine.id);
      selectedFine.checked = event.target.checked;
      setFines(localSelectedFines);
    };

  const _toggleAllFinesSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
    const basket: FineValue[] = [];
    _.forEach(individualUnpaidFines.data, (offender) => {
      _.forEach(offender.fines, (fine) => {
        if (fine.isPayable) {
          basket.push({
            checked: event.target.checked,
            id: fine.id,
            outstandingAmount: fine.total,
          });
        }
      });
    });
    setFines(basket);
  };

  const _isFineSelected = (fine: FineDetailDto): boolean => {
    const fineSelected = _.find(
      fines,
      (selectedFine) => selectedFine.id === fine.id && fine.isPayable,
    );
    if (fineSelected) return fineSelected.checked;
    return false;
  };

  const _areAllFinesSelected = (): boolean => {
    const nonCheckedFines = _.filter(fines, (fineValue) => !fineValue.checked);
    return !nonCheckedFines.length;
  };

  const _getPaidFines = () => {
    setIsUnpaid(false);
  };

  const _getUnpaidFines = () => {
    setIsUnpaid(true);
  };

  const _changeFilterData = (event: React.ChangeEvent<{ value: unknown }>) => {
    setFilter(event.target.value as string[]);
    setFines([]);
  };

  const _filterMenu = () =>
    _.map(individualFilterData.data, (member) => {
      return (
        <MenuItem key={member.idNumber} value={member.idNumber}>
          {member.fullName}
        </MenuItem>
      );
    });

  const _paySelectedFines = () => {
    history.push('/individual-basket', fines);
  };

  const getCheckedFines = () => {
    if (individualUnpaidFines?.data?.length !== 0) {
      return fines.filter((fine) => fine.checked === true).length;
    }
    return 0;
  };

  const isButtonDisabled = useMemo(() => getCheckedFines() === 0, [fines]);

  const _renderUnpaidFines = () => {
    return (
      <div className="flex flex-col w-full h-full">
        {responsive.isMedium && (
          <Card className="flex flex-row px-2 place-items-center overflow-visible">
            <Checkbox
              onChange={_toggleAllFinesSelected}
              checked={_areAllFinesSelected()}
              className="flex flex-grow-0 self-start"
            />
            <p>Select All</p>
          </Card>
        )}
        {individualUnpaidFines?.data?.length === 0 ? (
          <div className="flex flex-grow justify-center items-center my-2 xs:my-0">
            <span className="rounded-3xl p-1 text-black font-medium bg-form-background text-center">
              Congratulations !!!! <br />
              <br />
              You currently have no outstanding traffic fines. <br />
              Please SIGN IN regularly as the portal is updated in real time. <br />
              We will also email you a monthly statement if you get any new fines. <br />
              <br />
              Safe Motoring, Fines SA team
            </span>
          </div>
        ) : (
          _.map(individualUnpaidFines.data, (offender) => {
            const myFines = _.map(offender.fines, (localFine) => {
              if (offender.fines != null) {
                return (
                  <UnpaidFineSummary
                    key={localFine.id}
                    onCheck={_toggleFineSelected(localFine)}
                    checked={_isFineSelected(localFine)}
                    fine={localFine}
                    hasImage={localFine.evidence.length > 0}
                  />
                );
              }
              return <div>There were no new fines located</div>;
            });
            return (
              <div className="flex flex-col" key={`offender-${offender.offenderId}`}>
                <h2 className="text-white font-medium my-5">{`${offender.offenderName} (${offender.offenderId})`}</h2>
                {myFines}
              </div>
            );
          })
        )}
      </div>
    );
  };

  const _renderPaidFines = () => {
    return (
      <div className="w-full h-full">
        {_.map(individualPaidFines.data, (offender) => {
          const myFines = _.map(offender.fines, (localFine) => {
            return <PaidFineSummary key={localFine.id} fine={localFine} />;
          });
          return (
            <div key={`offender-${offender.offenderId}`}>
              <h2 className="text-white font-medium my-5">{`${offender.offenderName} (${offender.offenderId})`}</h2>
              {myFines.length !== 0 ? (
                myFines
              ) : (
                <h2 className="text-white font-medium my-5">No fines paid.</h2>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const _renderUnpaidHeaders = () => {
    if (!responsive.isMobile) {
      return (
        <div className="w-full h-14">
          <UnpaidTableRow
            className="h-11 md:flex hidden"
            checked={_areAllFinesSelected()}
            onCheck={_toggleAllFinesSelected}
            columns={[
              <p className="font-semibold">Fine Information</p>,
              <p className="font-semibold">Fine Amount</p>,
              <p className="font-semibold">Discount</p>,
              <p className="font-semibold">Other Fees</p>,
              <p className="font-semibold">Demerit Points</p>,
              <p className="font-semibold">Amount Paid</p>,
              <p className="font-semibold">Amount Outstanding</p>,
              <p className="font-semibold">Icon</p>,
            ]}
          />
        </div>
      );
    }
    return null;
  };

  const _renderPaidHeaders = () => {
    if (!responsive.isMobile) {
      return (
        <div className="w-full h-14">
          <PaidTableRow
            className="h-11 xs:flex hidden"
            columns={[
              <p className="font-semibold ">Fine Information</p>,
              <p className="font-semibold ">Fine Amount</p>,
              <p className="font-semibold">Total Paid</p>,
              <p className="font-semibold">PaymentType</p>,
            ]}
          />
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <div className="w-full h-full px-5 flex flex-col">
        <div className="flex md:flex-row flex-col w-full mb-5">
          <TailwindButton
            onClick={_getUnpaidFines}
            className={`bg-white hover:bg-gray-100 ${
              isUnpaid ? 'text-secondary z-10' : 'text-text-grey'
            }`}
          >
            Unpaid Fines
          </TailwindButton>
          <TailwindButton
            onClick={_getPaidFines}
            className={`bg-white hover:bg-gray-100 md:-ml-2  md:mt-0 -mt-1 mb-2 md:mb-0 ${
              !isUnpaid ? 'text-secondary z-10' : 'text-text-grey'
            }`}
          >
            Paid Fines
          </TailwindButton>
          <Select
            multiple
            value={filter}
            onChange={_changeFilterData}
            className="bg-white rounded-md md:mx-4 h-9 no-underline mb-2 md:mb-0"
            variant="outlined"
          >
            {_filterMenu()}
          </Select>
          <div className="flex flex-1" />
          {isUnpaid && !responsive.isMobile && (
            <div className="flex flex-row">
              <div className="flex flex-1" />
              <Chip
                label={`TOTAL: R ${
                  basketTotal !== 0 ? numberFormatter('#,###.', basketTotal) : ' 0'
                }`}
                className="text-white xs:mx-6  mb-2 xs:mb-0 h-9 bg-total-green font-semibold text-sm px-3 rounded-xl"
                variant="outlined"
              />
              <TailwindButton
                isDisabled={isButtonDisabled}
                onClick={_paySelectedFines}
                variant="contained"
                className="bg-tertiary hover:bg-tertiary-dark focus:bg-tertiary-dark"
              >
                Pay Selected Fines
              </TailwindButton>
            </div>
          )}
        </div>
        {isUnpaid ? _renderUnpaidHeaders() : _renderPaidHeaders()}
        <div className="w-full overflow-y-auto h-full inline-block my-1 rounded">
          <div className="h-full w-full">
            {isUnpaid ? _renderUnpaidFines() : _renderPaidFines()}
            {isUnpaid && responsive.isMobile && (
              <div className="flex flex-col">
                <div className="flex flex-1" />
                <Chip
                  label={`TOTAL: R ${
                    basketTotal !== 0 ? numberFormatter('#,###.', basketTotal) : ' 0'
                  }`}
                  className="text-white xs:mx-6  mb-2 xs:mb-0 h-9 bg-total-green font-semibold text-xl px-3 rounded-xl"
                  variant="outlined"
                />
                <TailwindButton
                  onClick={_paySelectedFines}
                  className="bg-tertiary hover:bg-tertiary-dark focus:bg-tertiary-dark text-white font-medium rounded-xl px-3 h-9"
                >
                  <img src="assets/icons/mail-icon.png" alt="mail" className="mr-2" />
                  Pay Selected Fines
                </TailwindButton>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles()}
        contentLabel="Mobile Modal"
      >
        {modalType === 'fineSyncing' && (
          <div className="flex w-full h-full">
            <div className="flex flex-grow justify-center items-center">
              <CircularProgress />
              <div className="ml-3">Fines Loading...</div>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default IndividualScreen;
